import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BikeChart = ({ stations, freeBike }) => {
    const [chartData, setChartData] = useState([]);
    const [xAxisStyle, setXAxisStyle] = useState({ fontSize: '14px' }); // Default font size for x-axis labels

    useEffect(() => {
        const data = {
            totalBikes: 0,
            disabledBikes: 0,
            freeBikes: freeBike.length,
            availableBikes: 0,
        };

        stations.forEach(station => {
            data.totalBikes += station.num_bikes_available + station.num_bikes_disabled;
            data.disabledBikes += station.num_bikes_disabled;
            data.availableBikes += station.num_bikes_available;
        });

        data.totalBikes += data.freeBikes;

        const formattedData = [
            { name: "Total", value: data.totalBikes, fill: "#a28fd0" },
            { name: "Available", value: data.availableBikes, fill: "#8884d8" },
            { name: "Disabled", value: data.disabledBikes, fill: "#82ca9d" },
            { name: "Free", value: data.freeBikes, fill: "#ff7300" },
        ];

        setChartData(formattedData);

        // Function to update x-axis label font size based on window width
        const updateXAxisStyle = () => {
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (width < 768) {
                setXAxisStyle({ fontSize: '10px' }); // Smaller screens get smaller font size
            } else {
                setXAxisStyle({ fontSize: '14px' }); // Larger screens get larger font size
            }
        };

        updateXAxisStyle();
        window.addEventListener('resize', updateXAxisStyle);
        return () => window.removeEventListener('resize', updateXAxisStyle);
    }, [stations, freeBike]); // React only when stations or freeBike changes

    return (
        <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
                <BarChart data={chartData} style={{ marginLeft: '-1.5rem' }} className=''>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={{ ...xAxisStyle }} />
                    <YAxis padding={-10} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BikeChart;


// import React, { useState, useEffect } from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Rectangle } from 'recharts';


// function CustomizedAxisTick({ x, y, stroke, payload }) {
//     const [date, time] = payload.value.split(' ');
//     return (
//         <g transform={`translate(${x},${y})`}>
//             <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" style={{ fontSize: '10px' }}>
//                 {date}
//                 {time && <tspan x={0} dy={20}>{time}</tspan>}
//             </text>
//         </g>
//     );
// }

// const BikeChart = ({ stations, freeBike }) => {
//     const [chartData, setChartData] = useState([]);

//     useEffect(() => {
//         // Initialize data sums
//         const data = {
//             totalBikes: 0,
//             disabledBikes: 0,
//             freeBikes: freeBike.length, // Assume freeBike is an array with free bikes info
//             availableBikes: 0,
//         };

//         // Summarize data from stations
//         stations.forEach(station => {
//             data.totalBikes += station.num_bikes_available + station.num_bikes_disabled;
//             data.disabledBikes += station.num_bikes_disabled;
//             data.availableBikes += station.num_bikes_available;
//         });

//         // Add free bikes to total bikes count
//         data.totalBikes += data.freeBikes;

//         // Prepare chart data from summarized values
//         const formattedData = [
//             { name: "Total", value: data.totalBikes, fill: "#a28fd0" },
//             { name: "Available", value: data.availableBikes, fill: "#8884d8" },
//             { name: "Disabled", value: data.disabledBikes, fill: "#82ca9d" },
//             { name: "Free", value: data.freeBikes, fill: "#ff7300" },
//         ];

//         setChartData(formattedData);
//     }, [stations, freeBike]); // React only when stations or freeBike changes


//     return (
//         <div style={{ width: '100%', height: 400 }}>
//             <ResponsiveContainer >
//                 <BarChart data={chartData} margin={{ top: 20, right: 0, left: -10, bottom: 0 }} >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="name" />
//                     <YAxis />
//                     <Tooltip />
//                     {/* <Legend /> */}
//                     <Bar dataKey="value" fill="#8884d8" barSize={50} />
//                 </BarChart>
//             </ResponsiveContainer>
//         </div>
//     );
// };

// export default BikeChart;
