import React, { useContext } from 'react';
import { forwardRef, useEffect, useRef } from 'react';

import {
    useTable,
    useRowSelect,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    state,
    useMountedLayoutEffect,
    usePagination,
    useSortBy,
    useFlexLayout,
} from 'react-table';
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
// import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter';
import { isEmpty } from 'lodash';
import Accordion from 'react-bootstrap/Accordion';
import { cookie } from '../../services/CookieService';
import { AccordionContext, Card, Dropdown, useAccordionButton } from 'react-bootstrap';

// Define a default UI for filtering
export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <>
            Search entire table:{' '}
            <span className='input-group input-group-sm mb-3'>
                <input
                    className='form-control'
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                // style={{
                //     fontSize: '1.1rem',
                //     border: '0',
                // }}
                />
            </span>
        </>
    );
}
// Define a default UI for filtering
export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, Header },
}) {
    const count = preFilteredRows.length;

    return (
        <>
            <div className='input-group input-group-sm'>
                <input
                    className='form-control'
                    value={filterValue || ''}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                    }}
                    placeholder={`Search ${Header}`}
                />
            </div>
        </>
    );
}

export function MakePlaceholderFilter({
    column: { filterValue, preFilteredRows, setFilter, Header },
}) {
    const count = preFilteredRows.length;

    return (
        <>
            {/* {console.log(Header)} */}
            <div className='input-group input-group-sm'>
                <input
                    className='form-control'
                    value={filterValue || ''}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                    }}
                    placeholder={`Search ${Header} records...`}
                />
            </div>
        </>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <>
            <div className='input-group input-group-sm'>
                <select
                    className='form-select'
                    value={filterValue}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined);
                    }}
                >
                    <option value=''>All</option>
                    {options.map((option, i) =>
                        option != '' && option != null ? (
                            <option key={i} value={option}>
                                {option}
                            </option>
                        ) : (
                            <option key={i} value={'undefined'}>
                                Blank
                            </option>
                        )
                    )}
                </select>
            </div>
        </>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <div className='d-flex justify-content-between'>
                <span className='input-group input-group-sm pe-2'>
                    <input
                        className='form-range'
                        type='range'
                        min={min}
                        max={max}
                        value={filterValue || min}
                        onChange={(e) => {
                            setFilter(parseInt(e.target.value, 10));
                        }}
                    />
                </span>
                <span>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => setFilter(undefined)}
                    >
                        Off
                    </button>
                </span>
            </div>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                className='form-control'
                value={filterValue[0] || ''}
                type='number'
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                className='form-control'
                value={filterValue[1] || ''}
                type='number'
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

//table custom buttons accordion start
function ContextAwareToggle({ children, eventKey, callback, className }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;
    let arrow = isCurrentEventKey ? ' ▲' : ' ▼';
    let label = children + arrow;
    return (
        <button
            type="button"
            className={`no-focus border-0 ${className}`}
            style={{
                color: '#000000',
                backgroundColor: '#f9fafb',
                opacity: isCurrentEventKey ? '0.8' : '1'
            }}
            onClick={decoratedOnClick}
        >
            {label}
        </button>
    );
}
//table custom buttons accordion end

// Column visiblity start
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <div className='cb action'>
            <label className='d-none'>
                <input type='checkbox' ref={resolvedRef} {...rest} />
                <span> All</span>
            </label>
        </div>
    );
});
// Column visiblity end

// Export table start
function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
        // CSV example
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data });
        return new Blob([csvString], { type: "text/csv" });
    }
    // else if (fileType === "xlsx") {
    //     // XLSX example

    //     const header = columns.map((c) => c.exportValue);
    //     const compatibleData = data.map((row) => {
    //         const obj = {};
    //         header.forEach((col, index) => {
    //             obj[col] = row[index];
    //         });
    //         return obj;
    //     });

    //     let wb = XLSX.utils.book_new();
    //     let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
    //         header,
    //     });
    //     XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    //     XLSX.writeFile(wb, `${fileName}.xlsx`);

    //     // Returning false as downloading of file is already taken care of
    //     return false;
    // }
    //PDF example
    if (fileType === "pdf") {
        const headerNames = columns.map((column) => column.exportValue);
        const doc = new JsPDF();
        doc.autoTable({
            head: [headerNames],
            body: data,
            margin: { top: 20 },
            styles: {
                minCellHeight: 9,
                halign: "left",
                valign: "center",
                fontSize: 11,
            },
        });
        doc.save(`${fileName}.pdf`);

        return false;
    }

    // Other formats goes here
    return false;
}
// Export table end


// Our table component
export function CustomTable({
    columns,
    data,
    globalFilter = false,
    selectedRows = '',
    onSelectedRowsChange = '',
    height = '450px',
    toggleColumnVisiblity = false,
    toggleExportData = true,
    isFlexLayout = false,
    initialState = {},
    setRefetchColumns,
    filterData = [],
    hiddenColumns = [],
}) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    console.log(row);
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows, // enable this if not using pagination
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        allColumns,
        getToggleHideAllColumnsProps,
        selectedFlatRows,
        state: { selectedRowIds, pageIndex, pageSize, filters },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        setAllFilters,
        exportData, // export table data
        setHiddenColumns,
    } = useTable(
        {
            columns,
            data,
            // initialState,
            initialState: {
                pageSize: cookie.get('default-page-size') ? cookie.get('default-page-size') : 25,
                filters: filterData,
                hiddenColumns: hiddenColumns,
            },
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            getExportFileBlob,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination, // usePagination!
        useRowSelect,
        useExportData,
        isFlexLayout && useFlexLayout
    );

    useMountedLayoutEffect(() => {
        let test = [];
        // console.log("SELECTED ROWS CHANGED", /*selectedFlatRows*/);
        selectedFlatRows.map((item) => {
            test.push(item.original);
        });
        onSelectedRowsChange && onSelectedRowsChange(test);
    }, [onSelectedRowsChange, selectedFlatRows]);

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10)

    // const cookie = new Cookie();

    function setDefaultPageSize(size) {
        setPageSize(size);
        cookie.set('default-page-size', size, { path: '' })
    }

    function showHideColmunsWithCookies(e, column, setRefetchColumns) {
        cookie.set(window.location.hash + '-column|' + column.id, column.isVisible, { path: '' })
        setRefetchColumns(new Date().getTime());
    }

    React.useEffect(() => {
        if (hiddenColumns && !isEmpty(hiddenColumns)) {

            setHiddenColumns(hiddenColumns)
        }
    }, [hiddenColumns])

    React.useEffect(() => {
        if (filterData && !isEmpty(filterData)) {
            // console.log(filterData)
            setAllFilters(filterData)
        }
    }, [filterData])

    return (
        <>

            <div className='table-responsive' style={{ height: height }}>
                {/* {toggleColumnVisiblity && (
                    <div className='mb-4'>
                        <Accordion className='mt-0'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header bsPrefix='accordion-header'>
                                    Show/Hide Columns
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <IndeterminateCheckbox
                                            {...getToggleHideAllColumnsProps()}
                                        />
                                    </div>
                                    {
                                        //Loop through columns data to create checkbox
                                    }
                                    <div className='row'>
                                        {allColumns.map((column) => (
                                            <div className='cb action col-sm-3' key={column.id}>
                                                <div className='form-check form-switch'>
                                                    <input
                                                        className='form-check-input'
                                                        id={column.Header + column.id}
                                                        type='checkbox'
                                                        // onClick={(e) => console.log(column.isVisible)}
                                                        onClick={(e) => showHideColmunsWithCookies(e, column, setRefetchColumns)}
                                                        {...column.getToggleHiddenProps()}
                                                    />{' '}
                                                    {
                                                        // console.log(column.id)
                                                    }
                                                    <label
                                                        className='form-check-label'
                                                        htmlFor={column.Header + column.id}
                                                    >
                                                        {column.Header}
                                                    </label>
                                                </div>
                                                    {
                                                        // <label>
                                                        //     <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                                                        //     <span>{column.Header}</span>
                                                        // </label>
                                                    }
                                                </div>
                                            ))}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                )} */}
                {
                    (toggleColumnVisiblity || toggleExportData) && (
                        <div className='mb-4'>
                            <Accordion>
                                <Card className='border-0 p-0'>
                                    <Card.Header className='bg-transparent border-0 p-0'>
                                        {
                                            toggleColumnVisiblity && (
                                                <span className='me-2'>
                                                    <ContextAwareToggle eventKey="0" className="btn btn-primary btn-sm">Show/Hide Columns</ContextAwareToggle>
                                                </span>
                                            )
                                        }
                                        {
                                            toggleExportData && (
                                                <span>
                                                    <ContextAwareToggle eventKey="1" className="btn btn-primary btn-sm">Export</ContextAwareToggle>
                                                </span>
                                            )
                                        }
                                    </Card.Header>
                                    {
                                        toggleColumnVisiblity && (
                                            <Accordion.Collapse eventKey="0" className='border-0'>
                                                <Card.Body className='p-0 pt-2 px-2'>
                                                    <>
                                                        <div>
                                                            <IndeterminateCheckbox
                                                                {...getToggleHideAllColumnsProps()}
                                                            />
                                                        </div>
                                                        {/* Loop through columns data to create checkbox */}
                                                        <div className='row'>
                                                            {allColumns.map((column) => (
                                                                <div className='cb action col-sm-3' key={column.id}>
                                                                    <div className='form-check form-switch'>
                                                                        <input
                                                                            className='form-check-input'
                                                                            id={column.Header + column.id}
                                                                            type='checkbox'
                                                                            // onClick={(e) => console.log(column.isVisible)}
                                                                            onClick={(e) => showHideColmunsWithCookies(e, column, setRefetchColumns)}
                                                                            {...column.getToggleHiddenProps()}
                                                                        />{' '}
                                                                        {
                                                                            // console.log(column.id)
                                                                        }
                                                                        <label
                                                                            className='form-check-label'
                                                                            htmlFor={column.Header + column.id}
                                                                        >
                                                                            {column.Header}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        )
                                    }
                                    {
                                        toggleExportData && (
                                            <Accordion.Collapse eventKey="1" className='border-0'>
                                                <Card.Body className='p-0 pt-2 px-2'>
                                                    <div>
                                                        <button className='btn btn-sm btn-info me-2'
                                                            onClick={() => { exportData("csv", true); }}>All as CSV</button>
                                                        <button className='btn btn-sm btn-secondary me-2'
                                                            onClick={() => { exportData("csv", false); }}>Current View as CSV</button>
                                                        {/* <button className='btn btn-sm btn-light me-2'
                                            onClick={() => { exportData("pdf", true); }}>All as PDF</button>
                                        <button className='btn btn-sm btn-light me-2'
                                            onClick={() => { exportData("pdf", false); }}>Current View as PDF</button> */}
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        )
                                    }
                                </Card>
                            </Accordion>
                        </div>
                    )
                }
                <table
                    className='table table-hover table-bordered table-sm'
                    {...getTableProps()}
                >
                    <thead className='border-light sticky-top'>
                        {globalFilter && (
                            <tr
                                style={{
                                    border: '0px solid #ffffff',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                }}
                            >
                                <th
                                    className='px-0'
                                    colSpan={visibleColumns.length}
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    {globalFilter && (
                                        <GlobalFilter
                                            preGlobalFilteredRows={preGlobalFilteredRows}
                                            globalFilter={state.globalFilter}
                                            setGlobalFilter={setGlobalFilter}
                                        />
                                    )}
                                </th>
                            </tr>
                        )}

                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className='bg-light border'
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className='align-top'
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' ▲'
                                                    : ' ▼'
                                                : ''}
                                        </span>
                                        {/* Render the columns filter UI */}
                                        <div>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='border-top'>
                        {/* {rows.map((row, i) => { //use this line with rows.map when not using pagination*/}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={i}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                style={{ fontSize: '0.9rem' }}
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-start'>
                        <div className='col-form-label col-form-label-sm pe-2 mt-1'>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                            | Go to page:{' '}
                        </div>
                        <div className='pe-2' style={{ maxWidth: '100px' }}>
                            <input
                                type='number'
                                className='form-control'
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const pageNumber = e.target.value
                                        ? Number(e.target.value - 1)
                                        : 0;
                                    gotoPage(pageNumber);
                                }}
                            />
                        </div>
                        <div>
                            <select
                                className='form-control'
                                value={pageSize}
                                onChange={(e) => setDefaultPageSize(Number(e.target.value))}
                                // onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 75].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='bd-highlight'>
                        <nav aria-label='Page navigation example'>
                            <ul className='pagination justify-content-end'>
                                <li className='page-item'>
                                    <button
                                        className={`page-link ${!canPreviousPage && 'disabled'}`}
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        {'<<'}
                                    </button>
                                </li>
                                <li className='page-item'>
                                    <button
                                        className={`page-link ${!canPreviousPage && 'disabled'}`}
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        Previous
                                    </button>
                                </li>
                                <li className='page-item'>
                                    <button
                                        className={`page-link ${!canNextPage && 'disabled'}`}
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        Next
                                    </button>
                                </li>
                                <li className='page-item'>
                                    <button
                                        className={`page-link ${!canNextPage && 'disabled'}`}
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        {'>>'}
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

// Define a custom filter filter function!
export function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';
