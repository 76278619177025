import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { cookie } from '../../services/CookieService';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';


const People = () => {

    // React-table start
    const [data, setData] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [refetchColumns, setRefetchColumns] = useState(0);

    function teamDetails(row) {
        if (row.teams && row.teams !== null && !isEmpty(row.teams)) {
            let teamFilter = '';
            row.teams.map((item, i) => {
                return (
                    teamFilter += item.name + ', '
                )
            })
            teamFilter = teamFilter.substring(0, teamFilter.length - 2);
            return teamFilter;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/people/view/${cell.row.original.id}`}>
                            {cell.value}
                        </Link>
                    </>
                )
            },
            {
                accessor: 'email',
                Header: 'Email',
            },
            {
                accessor: 'phone',
                Header: 'Phone',
            },
            {
                accessor: 'job_title',
                Header: 'Job Title',
            },
            {
                accessor: teamDetails,
                Header: 'Team',
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => (
                    (cell.row.original.teams && cell.row.original.teams !== '' && cell.row.original.teams !== null) &&
                    cell.row.original.teams.map((item, i) => {
                        return <span key={i}>
                            {/* <Link to={`/team/view/${item.id}`}> */}
                            {item.name}
                            {/* </Link> */}
                            {(i < cell.row.original.teams.length - 1) && ', '}
                        </span>
                    })
                )
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <ComponentAction
                            data={cell.row.original}
                            target='people'
                        />
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        // cookie based column visibility start
        let cookies = cookie.getAll();
        if (cookies && !isEmpty(cookies) && cookies !== null) {
            let hiddenColumnNames = [];
            Object.keys(cookies).map((item, i) => {
                if (item.includes(window.location.hash + '-column|') && (cookies[item] === 'true')) {
                    hiddenColumnNames.push(item.split("|")[1]);
                    // console.log(item);
                    // console.log(cookies[item]);
                }
            });
            setHiddenColumns(hiddenColumnNames);
        }
        // cookie based column visibility end

        AxiosGet('people').then(result => {
            setData(result.data.data);
            // console.log(result.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='People'
                buttons={
                    [
                        { url: 'people/add', label: 'Add People' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'people', label: 'People', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                        toggleColumnVisiblity={true}
                        setRefetchColumns={setRefetchColumns}
                        hiddenColumns={hiddenColumns}
                    />
                </div>
            </div>
        </>
    )
}
export default People;
