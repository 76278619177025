import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { Link } from 'react-router-dom';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';

const Categories = () => {

    // React-table start
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Category Name',
            },
            {
                accessor: 'typeName',
                Header: 'Category Type',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <ComponentAction
                            data={cell.row.original}
                            target='category'
                        />
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('categories').then(result => {
            // console.log(result.data.data);
            setData(result.data.data);
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Categories'
                buttons={
                    [
                        { url: 'category/add', label: 'Add Category' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'category', label: 'Categories', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                    />
                </div>
            </div>
        </>
    );
};
export default Categories
