import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { assetUrl, AxiosGet, ComponentLoading, ContentHeader } from '../../Components/Components'
import PdfPlaceholder from '../../Assets/Images/pdf_placeholder.png';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';

const ShowPartDetail = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        id: "",
        name: "",
        description: "",
        category: "",
        barcode: "",
    });
    const [documentList, setDocumentList] = useState('');

    useEffect(() => {
        AxiosGet('documents/part/' + params.id).then(result => {
            setDocumentList(result.data.data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [params.id]);

    // React-table start
    const [data, setData] = useState([]);

    function inStock(row) {
        return (row.quantity_total - row.quantity_consumed);
    }

    const columns = React.useMemo(
        () => [
            // {
            //     accessor: 'id',
            //     Header: 'Id',
            //     Cell: ({ cell }) => (
            //         <Link className='mx-2' to={`/inventory/view/${cell.row.values.id}`}>
            //             {cell.value}
            //         </Link>
            //     )
            // },
            {
                accessor: 'notes',
                Header: 'Description/Notes',
            },
            {
                accessor: 'location_name',
                Header: 'Location',
                Filter: SelectColumnFilter,
            },
            {
                accessor: inStock,
                Header: 'In Stock',
            },
            {
                accessor: 'avg_cost',
                Header: 'Cost(Avg)',
                Cell: ({ cell }) => {
                    return parseFloat(cell.value).toFixed(2);
                }

            },
            {
                accessor: 'stock_status',
                Header: 'Status',
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => {
                    let labelClass = '';
                    if (cell.row.values.stock_status.includes("In")) {
                        labelClass = 'bg-success';
                    } else if (cell.row.values.stock_status.includes("Out")) {
                        labelClass = 'bg-danger';
                    } else if (cell.row.values.stock_status.includes("Low")) {
                        labelClass = 'bg-warning';
                    }
                    return <span className={'badge ' + labelClass}>{cell.row.values.stock_status}</span>
                }
            },
            {
                id: 'action',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/inventory/view/${cell.row.original.id}`}>
                            Manage <i class="fa-solid fa-cart-flatbed"></i>
                        </Link>
                    </>
                )
            },
            // {
            //     // accessor: 'id',
            //     Header: 'Action',
            //     disableFilters: true,
            //     Cell: ({ cell }) => (
            //         <>
            //             {console.log(cell.row.original)}
            //             <ComponentAction
            //                 data={cell.row.original}
            //                 target='inventory'
            //             />
            //         </>
            //     )
            // },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('part/detail/' + params.id).then(result => {
            setData(result.data.data);
            setValues({
                id: result.data.part.id,
                name: result.data.part.name,
                category: result.data.part.category.name,
                barcode: result.data.part.barcode,
                description: result.data.part.description,
            });
            setLoading(false)
            // console.log(data)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [params.id]);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Part Details'
                buttons={
                    [
                        { url: 'part/edit/' + values['id'], label: 'Edit Part' },
                        { url: 'inventory/add/' + params.id, label: 'Add Inventory' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'parts', label: 'Parts', active: false },
                        { url: 'parts', label: 'Part Details', active: true },
                    ]
                }
            />
            {
                !loading ?

                    <div className='border rounded-2 shadow-sm'>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link active" id="basic-details-tab" data-bs-toggle="tab" data-bs-target="#basic-details" type="button" role="tab" aria-controls="basic-details" aria-selected="true">Basic Details</button>
                            </li>
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link" id="part-images-tab" data-bs-toggle="tab" data-bs-target="#part-images" type="button" role="tab" aria-controls="part-images" aria-selected="false">Images</button>
                            </li>
                        </ul>
                        <div className="tab-content pt-3 pb-2 px-3" id="myTabContent">
                            <div className="tab-pane fade show active" id="basic-details" role="tabpanel" aria-labelledby="basic-details-tab">
                                <div className='row pt-2'>
                                    <div className='col-12'>
                                        <h5 className=''>Part:</h5>
                                    </div>
                                </div>
                                {
                                    (!loading && values !== '') ?
                                        <>
                                            <div className='row px-2'>
                                                <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                    Name
                                                </div>
                                                <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                    {values.name}
                                                </div>
                                            </div>
                                            <div className='row px-2'>
                                                <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                    Description
                                                </div>
                                                <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                    {values.description}
                                                </div>
                                            </div>
                                            <div className='row px-2'>
                                                <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                    Category
                                                </div>
                                                <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                    {values.category}
                                                </div>
                                            </div>
                                            <div className='row px-2'>
                                                <div className='col-sm-2 px-3 py-2 border  border-right-0'>
                                                    Barcode
                                                </div>
                                                <div className='col-sm-10 py-2 border border-left-0'>
                                                    {values.barcode}
                                                </div>
                                            </div>
                                        </> : 'loading...'
                                }
                                <div className='row pt-4'>
                                    <div className='col-12'>
                                        <h5 className=''>Inventory:</h5>
                                    </div>
                                    <div className='col-12'>
                                        <CustomTable
                                            columns={columns}
                                            data={data}
                                            height='auto'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="part-images" role="tabpanel" aria-labelledby="part-images-tab">
                                <div className='row pt-2'>
                                    <div className='col-12'>
                                        <h5 className=''>Images:</h5>
                                        <div className='d-flex flex-wrap'>

                                        
                                        {
                                            (!loading && documentList && documentList !== '') ?
                                                documentList.map((item, i) => (
                                                    <div key={i} className='col-auto d-flex flex-column my-2 pe-2'>
                                                        <div className="card shadow-sm" style={{ width: '14rem' }}>
                                                            <img
                                                                src={item.document_path.split('.').reverse()[0] === 'pdf' ? PdfPlaceholder : assetUrl + item.document_path}
                                                                style={{ height: '150px', objectFit: 'cover' }}
                                                                className="card-img-top"
                                                                alt={item.name} />
                                                            <div className="card-body py-2">
                                                                <h6 className="card-text">{item.name}</h6>
                                                                <p className="card-text" style={{ fontSize: '0.85rem' }}>
                                                                    {
                                                                        item.description !== '' && item.description !== null ?
                                                                            item.description : 'No description available!'
                                                                    }
                                                                </p>
                                                                <div className='d-flex justify-content-between'>
                                                                    {/* <span
                                                                    onClick={() => { removeDocument(item.id) }}
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="card-link">
                                                                    <i className='fas fa-trash-alt text-danger'></i>
                                                                </span> */}
                                                                    <a href={assetUrl + item.document_path}
                                                                        target='_blank'
                                                                        rel="noreferrer" 
                                                                        className="card-link text-decoration-none">
                                                                        View &nbsp;
                                                                        <i className="fas fa-external-link"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <span>
                                                    No documents added yet...
                                                </span>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    : <ComponentLoading />}
        </>
    );
};
export default ShowPartDetail
