import React, { useEffect, useState } from 'react'
import { AxiosGet, ComponentInput, ComponentTextarea, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components'
import { Toast } from 'react-bootstrap';

const AddLocation = () => {

    const [dropdown, setDropdown] = useState('');
    // const [loading, setLoading] = useState('');
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");

    const initialFormValues = {
        name: "",
        reference_id: "",
        address: "",
        longitude: "",
        latitude: "",
        map_address: "",
        has_parent: false,
        parent_location_id: "",
        notes: "",
    };

    const [values, setValues] = useState(initialFormValues);

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);


    useEffect(() => {
        (async () => {
            const locations = await AxiosGet('locations');
            setDropdown({ locations });
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Add Location'
                buttons={
                    [
                        { url: 'locations', label: 'All Locations' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'locations', label: 'Locations', active: false },
                        { url: 'location/add', label: 'Add', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>

                <form>
                    <div className="row p-3">
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='reference_id'
                                    type='text'
                                    displayName='Location Reference Id'
                                    className='form-control'
                                    value={values['reference_id']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Location Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='address'
                                    type='text'
                                    displayName='Location Address'
                                    className='form-control'
                                    value={values['address']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='longitude'
                                    type='text'
                                    displayName='Longitude'
                                    className='form-control'
                                    value={values['longitude']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='latitude'
                                    type='text'
                                    displayName='latitude'
                                    className='form-control'
                                    value={values['latitude']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='map_address'
                                    type='text'
                                    displayName='Google Map Link'
                                    className='form-control'
                                    value={values['map_address']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3 ">
                                <div className="form-check ">
                                    <input className="form-check-input" type="checkbox" name='has_parent' id="hasParent"
                                        checked={values['has_parent']}
                                        onChange={(e) => onChange(e, values, setValues)} />
                                    <label className="form-check-label" htmlFor="hasParent">
                                        This is a child location
                                    </label>
                                    <span id="has_parent-error" className="error text-danger block">
                                        {(error) && error['has_parent']}
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label>Select Parent Location</label>
                                <select
                                    name='parent_location_id'
                                    className="form-control"
                                    required
                                    value={values['parent_location_id']}
                                    disabled={!values['has_parent']}
                                    onChange={(e) => onChange(e, values, setValues)}>
                                    <option>Select Parent</option>
                                    {
                                        dropdown.locations && dropdown.locations.data.data !== '' && dropdown.locations.data.data !== null &&
                                        dropdown.locations.data.data.map((item, i) =>
                                            <option value={item.id} key={i}>{item.name}</option>
                                        )
                                    }

                                </select>
                                <span id="categoryName-error" className="error text-danger">
                                    {(error) && error['parent_location_id']}
                                </span>
                            </div>
                            <div className="mb-3">
                                <ComponentTextarea
                                    name='notes'
                                    type='text'
                                    displayName='Notes'
                                    className='form-control'
                                    value={values['notes']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="col-12">
                                <button onClick={
                                    (e) => {
                                        handleSubmitForStore(
                                            e,
                                            'location/add',
                                            {
                                                reference_id: values.reference_id,
                                                name: values.name,
                                                address: values.address,
                                                longitude: values.longitude,
                                                latitude: values.latitude,
                                                notes: values.notes,
                                                map_address: values.map_address,
                                                has_parent: values.has_parent,
                                                parent_location_id: (values.has_parent === false) ? 0 : values.parent_location_id,
                                            },
                                            setError,
                                            setNotificationMessage,
                                            setValues,
                                            initialFormValues
                                        )
                                    }
                                } className="btn btn-primary">Submit</button>
                                {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                    <Toast.Header>
                                        <img
                                            src="holder.js/20x20?text=%20"
                                            className="rounded me-2"
                                            alt=""
                                        />
                                        <strong className="me-auto">Location</strong>
                                        <small>Added</small>
                                    </Toast.Header>
                                    <Toast.Body>{notificationMessage}</Toast.Body>
                                </Toast> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>

    )
}

export default AddLocation