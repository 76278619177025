import { useLocation, Navigate, Outlet } from "react-router-dom";
import CookieService from "../../services/CookieService";

const RequireAuth = ({ allowedRoles }) => {
    // const { auth } = useAuth();
    const location = useLocation();
    // auth.roles = 2001;
    // console.log({ auth });
    const token = CookieService.get('access_token');
    return (
        <>
            {
                !token
                    ? <Navigate to="login" state={{ from: location }} replace />
                    : <Outlet token={token} />
            }
        </>
    );
}

export default RequireAuth;