import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { AxiosGet, ComponentInput, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForUpdate, onChange, useAxios } from '../../Components/Components'
import { Toast } from 'react-bootstrap';

const EditProfile = () => {
    const params = useParams()
    const [dropdown, setDropdown] = useState('');
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        job_title: "",
        team_id: "",
        hourly_rate: "",
        notes: "",
        user_id: "",
        status: "",
    });

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);

    const [userDetail] = useAxios({
        method: 'GET',
        endPoint: 'user-detail',
    });

    useEffect(() => {
        (async () => {
            const user_id = await AxiosGet('auth-user');
            // const users = await AxiosGet('users-for-people?user=' + user_id.data.data.id);
            const teams = await AxiosGet('dropdown/teams');
            setDropdown({ teams });
            const fetchedData = await AxiosGet('people/' + user_id.data.data.id);
            setValues({
                id: user_id.data.data.id,
                name: fetchedData.data.data.name,
                email: fetchedData.data.data.email,
                phone: fetchedData.data.data.phone,
                job_title: fetchedData.data.data.job_title,
                team_id: fetchedData.data.data.team_id,
                hourly_rate: fetchedData.data.data.hourly_rate,
                notes: fetchedData.data.data.notes,
                user_id: fetchedData.data.data.user_id,
                status: fetchedData.data.data.status,
            });
            setSelectedTeam(fetchedData.data.team)
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Edit People'
                buttons={
                    [
                        { url: 'profile', label: 'Profile' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'profile', label: 'Profile', active: false },
                        { url: 'people/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <form>
                    <div className="row p-3">
                        <div className='col-sm-6'>
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Full Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='email'
                                    type='email'
                                    displayName='Email'
                                    className='form-control'
                                    value={values['email']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='phone'
                                    type='text'
                                    displayName='Contact No.'
                                    className='form-control'
                                    value={values['phone']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            {/* {

                                <div className="mb-3">
                                    {
                                        dropdown.users &&
                                        <ComponentSelect
                                            name='user_id'
                                            displayName='User'
                                            className='form-select'
                                            options={dropdown.users.data.data}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['user_id']}
                                            label='username'
                                            value='id'
                                            error={error}
                                            disabled={userDetail && userDetail.role > 1 ? false: true}
                                        />
                                    }
                                </div>
                            } */}
                            <div className='mb-3'>
                                <ComponentSelect
                                    name='status'
                                    displayName='Status'
                                    className='form-select'
                                    options={[
                                        { value: 1, label: 'Active' },
                                        { value: 0, label: 'Inactive' },
                                    ]}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    selected={values['status']}
                                    label='label'
                                    value='value'
                                    error={error}
                                    disabled={userDetail && userDetail.role > 2 ? false : true}
                                />
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='mb-3'>
                                <label htmlFor='team_id' className='form-label fw-semibold'>
                                    Team
                                </label>
                                {dropdown.teams && selectedTeam != '' &&
                                    <Select
                                        name='team_id'
                                        defaultValue={selectedTeam}
                                        onChange={setSelectedTeam}
                                        options={dropdown.teams.data}
                                        isClearable={true}
                                        isMulti
                                    isDisabled={userDetail && userDetail.role > 2 ? false : true}
                                    />
                                }
                                <span
                                    id='team_id-error'
                                    className='error text-danger'
                                >
                                    {error && error['team_id']}
                                </span>
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='job_title'
                                    type='text'
                                    displayName='Job Title'
                                    className='form-control'
                                    value={values['job_title']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                    disabled={userDetail && userDetail.role > 2 ? false : true}
                                />
                            </div>
                            {/* <div className="mb-3">
                                <ComponentInput
                                    name='hourly_rate'
                                    type='text'
                                    displayName='hourly Rate'
                                    className='form-control'
                                    value={values['hourly_rate']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div> */}
                            <div className="mb-3">
                                <ComponentTextarea
                                    name='notes'
                                    type='text'
                                    displayName='Notes'
                                    className='form-control'
                                    value={values['notes']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                    disabled={userDetail && userDetail.role > 2 ? false : true}
                                />
                            </div>

                        </div>
                        <div className='col-12'>
                            <button onClick={
                                (e) => {
                                    let teams = [];
                                    if (selectedTeam != null && selectedTeam != '') {
                                        selectedTeam.map((item) => {
                                            teams.push(item.value);
                                        });
                                    }
                                    handleSubmitForUpdate(
                                        e,
                                        'people/edit',
                                        {
                                            id: values.id,
                                            name: values.name,
                                            email: values.email,
                                            phone: values.phone,
                                            job_title: values.job_title,
                                            team_id: teams.toString(),
                                            hourly_rate: values.hourly_rate,
                                            notes: values.notes,
                                            user_id: values.user_id,
                                            status: values.status,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""
                                    />
                                    <strong className="me-auto">Profile</strong>
                                    <small>Updated</small>
                                </Toast.Header>
                                <Toast.Body>{notificationMessage}</Toast.Body>
                            </Toast> */}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditProfile