import { useState } from 'react';
import Scanner from 'qrcode-scanner-react';

function ReadQr({ setQrScannedPartId, id = '' }) {
	const [scanning, setScanning] = useState(false);
	const [result, setResult] = useState('');

	function scanSuccess(result) {
		setScanning(false);
		setResult(result);
		setQrScannedPartId([
			{
				id: id,
				value: result,
			},
		]);
		// console.log(result);
	}

	function startScan() {
		setResult('');
		setScanning(true);
	}

	return (
		<div>
			{scanning ? (
				<>
					<button className='btn btn-sm btn-secondary' onClick={() => setScanning(false)}>Stop Scanning</button>
					<Scanner scanning={scanning} scanSuccess={scanSuccess} />
				</>
			) : (
				<button className='btn btn-sm btn-secondary' onClick={startScan}>Scan QR</button>
			)}
			{/* <p>Result: {result}</p> */}
		</div>
	);
}

export default ReadQr;
