import Cookie from "universal-cookie";

export const cookie = new Cookie();


class CookieServiceClass {
    get(key) {
        return cookie.get(key);
    }

    set(key, value, options) {
        cookie.set(key, value, options);
    }

    remove(key) {
        cookie.remove(key);
    }

    getAll() {
        cookie.getAll();
    }
}

const CookieService = new CookieServiceClass();

export default CookieService;