import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { HashRouter, BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Pages/Auth/Login';
import Logout from './Pages/Auth/Logout';
import { bearerToken } from './Components/Components';
import ResetPassword from './Pages/Auth/ResetPassword';

ReactDOM.render(
    //enable below 2 commented components to use hash routing and coment browser router
    <React.StrictMode>
        <HashRouter >
            {/* <BrowserRouter> */}
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="logout" element={<Logout />} />
                {
                    (bearerToken !== undefined) &&
                    <Route path="/*" element={<App />} />
                }
            </Routes>
            {/* </BrowserRouter> */}

        </HashRouter>
    </React.StrictMode>

    //enable above 2 commented components to use hash routing and coment browser router
    // <React.StrictMode>
    //     <HashRouter >
    ,
  document.getElementById('root')
);
