import moment from 'moment/moment';
import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Brush, Legend, Radio } from 'recharts';

const fetchData = async () => {
    return fetch('SampleData/ExpandedStationUsageLog.json').then(response => response.json());
};

function CustomizedAxisTick({ x, y, stroke, payload }) {
    const [date, time] = payload.value.split(',');
    let time12h = moment(payload.value).format('hh A');
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {date}
                {time && <tspan x={0} dy={20}>{time12h}</tspan>}
            </text>
        </g>
    );
}

function StationTimeSeries() {
    const [data, setData] = useState([]);
    const [zoomLevel, setZoomLevel] = useState('hourly');
    const [legendDescription, setLegendDescription] = useState('Total Difference');

    useEffect(() => {
        fetchData().then(rawData => {
            const processedData = processDataSet(rawData, zoomLevel);
            setData(processedData);
        });
    }, [zoomLevel]);

    const processDataSet = (data, zoomLevel) => {
        const timeRanges = {
            '06:00:00': '07:00:00',
            '09:00:00': '10:00:00',
            '12:00:00': '13:00:00',
            '15:00:00': '16:00:00',
            '18:00:00': '19:00:00'
        };
        let sums = {};

        data.forEach(item => {
            const timestamp = new Date(item.timestamp);
            const formattedTime = timestamp.toTimeString().substring(0, 8);
            const date = timestamp.toISOString().substring(0, 10);
            const year = timestamp.getFullYear();
            const month = timestamp.getMonth();
            const week = Math.floor((timestamp.getDate() - 1) / 7);

            // Identify if the current timestamp falls within any of the specified time ranges
            let withinRange = false;
            for (const [start, end] of Object.entries(timeRanges)) {
                if (formattedTime >= start && formattedTime < end) {
                    withinRange = true;
                    break;
                }
            }
            if (!withinRange) return; // Skip processing if time is not within range

            let rangeKey;
            switch (zoomLevel) {
                case 'daily':
                    rangeKey = date;
                    break;
                case 'weekly':
                    rangeKey = `Week ${week + 1}, ${month + 1}/${year}`;
                    break;
                case 'monthly':
                    rangeKey = `${month + 1}/${year}`;
                    break;
                case 'yearly':
                    rangeKey = `${year}`;
                    break;
                default:
                    rangeKey = `${date}, ${formattedTime}`;
                    break;
            }

            const totalDocks = item.num_docks_available;
            const totalAvailable = item.num_bikes_available + item.num_docks_available;
            const currentValue = totalAvailable - totalDocks;
            sums[rangeKey] = (sums[rangeKey] || 0) + currentValue;
        });

        const results = Object.keys(sums).map(key => ({
            time: key,
            value: sums[key]
        }));
        // Calculate the sum of all values
        const sum = results.reduce((acc, obj) => acc + obj.value, 0);

        // Calculate the average
        const average = (sum / results.length).toFixed(0);

        setLegendDescription(average);

        return results;
    };

    const legendFormatter = (value, entry, index) => {
        const aggregationType = 'Average ';
        return `${zoomLevel.charAt(0).toUpperCase() + zoomLevel.slice(1)} ${aggregationType}: ${legendDescription}`;
    };


    return (
        <div style={{ height: '300px' }}>
            <div style={{ marginBottom: '20px' }} className='d-flex justify-content-center'>
                {['hourly', 'daily', 'weekly', 'monthly', 'yearly'].map(level => (
                    <label key={level} className='px-2'>
                        <input
                            type="radio"
                            value={level}
                            checked={zoomLevel === level}
                            onChange={(e) => setZoomLevel(e.target.value)}
                        /> {level.charAt(0).toUpperCase() + level.slice(1)}
                    </label>
                ))}
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    {/* <XAxis dataKey="time" height={60} /> */}
                    <XAxis dataKey="time" height={60} tick={CustomizedAxisTick} />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                    {/* <Brush dataKey="time" height={30} stroke="#8884d8" /> */}
                    <Legend formatter={legendFormatter} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export default StationTimeSeries;



// import React, { useState, useEffect } from 'react';
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Brush, Legend, Radio, Text } from 'recharts';

// const fetchData = async () => {
//     return fetch('SampleData/ExpandedStationUsageLog.json').then(response => response.json());
// };

// function CustomizedAxisTick({ x, y, stroke, payload }) {
//     const [date, time] = payload.value.split(',');
//     return (
//         <g transform={`translate(${x},${y})`}>
//             <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
//                 {date}
//                 {time && <tspan x={0} dy={20}>{time}</tspan>}
//             </text>
//         </g>
//     );
// }

// function StationTimeSeries() {
//     const [data, setData] = useState([]);
//     const [zoomLevel, setZoomLevel] = useState('hourly'); // 'hourly', 'daily', 'weekly', 'monthly', 'yearly'

//     useEffect(() => {
//         fetchData().then(rawData => {
//             const processedData = processDataSet(rawData, zoomLevel);
//             setData(processedData);
//         });
//     }, [zoomLevel]);

//     const processDataSet = (data, zoomLevel) => {
//         let sums = [];
//         let counts = {};

//         data.forEach(item => {
//             const timestamp = new Date(item.timestamp);
//             const year = timestamp.getFullYear();
//             const month = timestamp.getMonth();
//             const week = Math.floor((timestamp.getDate() - 1) / 7);
//             const date = timestamp.toISOString().substring(0, 10); // Get the date in YYYY-MM-DD format
//             const formattedTime = timestamp.toTimeString().substring(0, 8);

//             let rangeKey;
//             switch (zoomLevel) {
//                 case 'daily':
//                     rangeKey = date;
//                     break;
//                 case 'weekly':
//                     rangeKey = `Week ${week + 1}, ${month + 1}/${year}`;
//                     break;
//                 case 'monthly':
//                     rangeKey = `${month + 1}/${year}`;
//                     break;
//                 case 'yearly':
//                     rangeKey = `${year}`;
//                     break;
//                 default:
//                     rangeKey = `${date}, ${formattedTime}`;
//                     break;
//             }

//             // const totalDocks = item.num_docks_available;
//             // const totalAvailable = item.num_bikes_available + item.num_docks_available;
//             // const currentValue = totalAvailable - totalDocks;
//             // const index = sums.findIndex(e => e.time === rangeKey);
//             // if (index > -1) {
//             //     sums[index].value += currentValue;
//             // } else {
//             //     sums.push({ time: rangeKey, value: currentValue });
//             // }
//             counts[rangeKey] = (counts[rangeKey] || 0) + 1;
//             const totalDocks = item.num_docks_available;
//             const totalAvailable = item.num_bikes_available + item.num_docks_available;
//             const currentValue = totalAvailable - totalDocks;
//             sums[rangeKey] = (sums[rangeKey] || 0) + currentValue;
//         });

//         return Object.keys(sums).map(key => ({
//             time: key,
//             value: zoomLevel === 'hourly' ? sums[key] : sums[key] / counts[key] // Average if not hourly
//         }));
//         return sums;
//     };
//     // const legendFormatter = (value, entry, index) => {
//     //     return `${zoomLevel.charAt(0).toUpperCase() + zoomLevel.slice(1)} Data: ${value}`;
//     // };
//     const legendFormatter = (value, entry, index) => {
//         const aggregationType = zoomLevel === 'hourly' ? 'Total Difference' : 'Average Difference';
//         console.log(value, entry, index)
//         return `${zoomLevel.charAt(0).toUpperCase() + zoomLevel.slice(1)} ${aggregationType}: ${value}`;
//     };
//     return (
//         <div>
//             <div style={{ marginBottom: '20px' }}>
//                 <label>
//                     <input
//                         type="radio"
//                         value="hourly"
//                         checked={zoomLevel === 'hourly'}
//                         onChange={(e) => setZoomLevel(e.target.value)}
//                     /> Hourly
//                 </label>
//                 <label>
//                     <input
//                         type="radio"
//                         value="daily"
//                         checked={zoomLevel === 'daily'}
//                         onChange={(e) => setZoomLevel(e.target.value)}
//                     /> Daily
//                 </label>
//                 <label>
//                     <input
//                         type="radio"
//                         value="weekly"
//                         checked={zoomLevel === 'weekly'}
//                         onChange={(e) => setZoomLevel(e.target.value)}
//                     /> Weekly
//                 </label>
//                 <label>
//                     <input
//                         type="radio"
//                         value="monthly"
//                         checked={zoomLevel === 'monthly'}
//                         onChange={(e) => setZoomLevel(e.target.value)}
//                     /> Monthly
//                 </label>
//                 <label>
//                     <input
//                         type="radio"
//                         value="yearly"
//                         checked={zoomLevel === 'yearly'}
//                         onChange={(e) => setZoomLevel(e.target.value)}
//                     /> Yearly
//                 </label>
//                 {/* More options for Weekly, Monthly, Yearly */}
//             </div>
//             <ResponsiveContainer width="100%" height={400}>
//                 <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="time" height={60} tick={CustomizedAxisTick} />
//                     <YAxis />
//                     <Tooltip />
//                     <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
//                     <Brush dataKey="time" height={30} stroke="#8884d8" />
//                     <Legend formatter={legendFormatter} />
//                 </AreaChart>
//             </ResponsiveContainer>
//         </div>
//     );
// }

// export default StationTimeSeries;



// import React, { useState, useEffect } from 'react';
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Brush, Legend, Radio } from 'recharts';

// const fetchData = async () => {
//     return fetch('SampleData/ExpandedStationUsageLog.json').then(response => response.json());
// };

// function StationTimeSeries() {
//     const [data, setData] = useState([]);
//     const [zoomLevel, setZoomLevel] = useState('hourly'); // 'hourly' or 'daily'

//     useEffect(() => {
//         fetchData().then(rawData => {
//             const processedData = processDataSet(rawData, zoomLevel);
//             setData(processedData);
//         });
//     }, [zoomLevel]);

//     const processDataSet = (data, zoomLevel) => {
//         const timeRanges = {
//             '06:00:00': '07:00:00',
//             '12:00:00': '13:00:00',
//             '15:00:00': '16:00:00',
//             '18:00:00': '19:00:00'
//         };
//         let sums = [];

//         data.forEach(item => {
//             const timestamp = new Date(item.timestamp);
//             const date = timestamp.toISOString().substring(0, 10); // Get the date in YYYY-MM-DD format
//             const formattedTime = timestamp.toTimeString().substring(0, 8);

//             Object.entries(timeRanges).forEach(([start, end]) => {
//                 if (formattedTime >= start && formattedTime < end) {
//                     const rangeKey = zoomLevel === 'daily' ? date : `${date} ${start}`;
//                     const index = sums.findIndex(e => e.time === rangeKey);
//                     const totalDocks = item.num_docks_available;
//                     const totalAvailable = item.num_bikes_available + item.num_docks_available;
//                     const currentValue = totalAvailable - totalDocks;
//                     if (index > -1) {
//                         sums[index].value += currentValue;
//                     } else {
//                         sums.push({ time: rangeKey, value: currentValue });
//                     }
//                 }
//             });
//         });

//         return sums;
//     };

//     const handleZoomChange = (event) => {
//         setZoomLevel(event.target.value);
//     };

//     const formatXAxis = (tickItem) => {
//         // Adjust this to change how you format your tick labels
//         const [date, time] = tickItem.split(' ');
//         const formattedDate = date.substring(5).replace('-', '/');
//         const formattedTime = time === '06:00:00' ? '6 AM' :
//             time === '12:00:00' ? '12 PM' :
//                 time === '15:00:00' ? '3 PM' :
//                     time === '18:00:00' ? '6 PM' : '';
//         return `${formattedDate} <br/> ${formattedTime}`;
//     };

//     return (
//         <div>
//             <div style={{ marginBottom: '20px' }}>
//                 <label>
//                     <input
//                         type="radio"
//                         value="hourly"
//                         checked={zoomLevel === 'hourly'}
//                         onChange={handleZoomChange}
//                     /> Hourly
//                 </label>
//                 <label>
//                     <input
//                         type="radio"
//                         value="daily"
//                         checked={zoomLevel === 'daily'}
//                         onChange={handleZoomChange}
//                     /> Daily
//                 </label>
//             </div>
//             <ResponsiveContainer width="100%" height={400}>
//                 <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="time" tickFormatter={formatXAxis} />
//                     <YAxis />
//                     <Tooltip />
//                     <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
//                     <Brush dataKey="time" height={30} stroke="#8884d8" />
//                     <Legend />
//                 </AreaChart>
//             </ResponsiveContainer>
//         </div>
//     );
// }

// export default StationTimeSeries;

