import React from 'react';
import { Link } from "react-router-dom"

const NavItem = (data) => {
    // console.log(data)
    if (data) {
        data = data.data
    } else {
        return false
    }
    const groupOne = [];
    const groupTwo = [];
    const groupThree = [];
    data.map((item, i) => {
        if (item.show_in_nav) {
            // console.log(item);
            if (item.group_id === 1) {
                groupOne.push({
                    'key': i,
                    'label': item.menu_label,
                    'path': item.frontend_path,
                    'icon': item.icon_class,
                })
            }
            if (item.group_id === 2) {
                groupTwo.push({
                    'key': i,
                    'label': item.menu_label,
                    'path': item.frontend_path,
                    'icon': item.icon_class,
                })
            }
            if (item.group_id === 3) {
                groupThree.push({
                    'key': i,
                    'label': item.menu_label,
                    'path': item.frontend_path,
                    'icon': item.icon_class,
                })
            }
        }
    }
    )

    return (
        <>
            <div className="sb-sidenav-menu-heading">Analytics</div>
            <Link to='/' className="nav-link">
                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt" /></div>
                Dashboard
            </Link>
            {
                (groupOne !== '') &&
                <div className="sb-sidenav-menu-heading">Work & Repairs</div>
            }
            {
                (groupOne) &&
                groupOne.map((item, i) =>
                    <Link to={item.path} key={i} className="nav-link">
                        <div className="sb-nav-link-icon"><i className={item.icon} /></div>
                        {item.label}
                    </Link>
                )
            }
            {
                (groupTwo !== '') &&
                <div className="sb-sidenav-menu-heading">Assets & Inventory</div>
            }
            {
                (groupTwo) &&
                groupTwo.map((item, i) =>
                    <Link to={item.path} key={i} className="nav-link">
                        <div className="sb-nav-link-icon"><i className={item.icon} /></div>
                        {item.label}
                    </Link>
                )
            }
            {
                (groupThree !== '') &&
                <div className="sb-sidenav-menu-heading">Lists & Settings</div>

            }
            {
                (groupThree) &&
                groupThree.map((item, i) =>
                    <Link to={item.path} key={i} className="nav-link">
                        <div className="sb-nav-link-icon"><i className={item.icon} /></div>
                        {item.label}
                    </Link>
                )
            }
            {/* <div className="sb-sidenav-menu-heading">Custom Modules</div>
            <Link to='/custom-module/station-removal' className="nav-link">
                <div className="sb-nav-link-icon"><i className='fa fa-cog' /></div>
                Station Removal
            </Link> */}
        </>
    )
}

export default NavItem