import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosGet, ComponentLabelValueRow, ContentHeader } from '../../Components/Components'

const ShowPeople = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)

    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        job_title: "",
        team_id: "",
        hourly_rate: "",
        notes: "",
        user_id: "",
        status: "",
    });

    useEffect(() => {
        (async () => {
            const fetchedData = await AxiosGet('people/' + params.id);
            setValues({
                name: fetchedData.data.data.name,
                email: fetchedData.data.data.email,
                phone: fetchedData.data.data.phone,
                job_title: fetchedData.data.data.job_title,
                team: fetchedData.data.team && fetchedData.data.team,
                hourly_rate: fetchedData.data.data.hourly_rate,
                notes: fetchedData.data.data.notes,
                user_id: fetchedData.data.user && fetchedData.data.user.id,
                user_name: fetchedData.data.user && fetchedData.data.user.username,
                status: fetchedData.data.data.status,
            });
            setLoading(false)
        })();
    }, [params.id]);

    return (
        <>
            <ContentHeader
                h1='People Details'
                buttons={
                    [
                        { url: 'people/edit/' + params.id, label: 'Edit People' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'people', label: 'People Details', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm mb-4'>
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link active" id="people-details-tab" data-bs-toggle="tab" data-bs-target="#people-details" type="button" role="tab" aria-controls="people-details" aria-selected="true">Details</button>
                            </li>
                            {/* <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                                <button className="nav-link" id="work-orders-tab" data-bs-toggle="tab" data-bs-target="#work-orders" type="button" role="tab" aria-controls="work-orders" aria-selected="false">Work Orders</button>
                            </li> */}
                        </ul>
                        <div className="tab-content py-3 px-3" id="myTabContent">
                            <div className="tab-pane fade show active" id="people-details" role="tabpanel" aria-labelledby="people-details-tab">
                                {
                                    (!loading && values !== '') ?
                                        <>
                                            <div className='row pt-2'>
                                                <div className='col-12'>
                                                    <h6 className=''>Basic Information</h6>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Name'
                                                        value={values.name}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Email'
                                                        value={values.email}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Contact no.'
                                                        value={values.phone}
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Username'
                                                        value={values.user_name}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <ComponentLabelValueRow
                                                        label='Job Title'
                                                        value={values.job_title}
                                                    />
                                                    {/* <ComponentLabelValueRow
                                                        label='Hourly Rate'
                                                        value={values.hourly_rate}
                                                    /> */}
                                                    <ComponentLabelValueRow
                                                        label='Status'
                                                        value={
                                                            (values.status) && (values.status === 1) ? <span className="badge bg-success">Active</span> : <span className="badge bg-dark text-light">Inactive</span>
                                                        }
                                                    />
                                                    <ComponentLabelValueRow
                                                        label='Team(s)'
                                                        value={values.team ? values.team : 'N/A'}
                                                        valueIsArray={true}
                                                        isLink={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pt-4'>
                                                <div className='col-12'>
                                                    <h6 className=''>Notes</h6>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <ComponentLabelValueRow
                                                        label='Notes'
                                                        value={values.notes}
                                                    />
                                                </div>
                                            </div>
                                        </> : 'loading...'
                                }
                            </div>
                            <div className="tab-pane fade" id="work-orders" role="tabpanel" aria-labelledby="work-orders-tab">
                                <div className='row'>
                                    <div className='col-12'>
                                        work-orders
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ShowPeople