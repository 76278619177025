import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosGet, ComponentLabelValueRow, ComponentLoading, ContentHeader } from '../../Components/Components'

const ShowContact = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)

    const [values, setValues] = useState({
        company_name: "",
        primary_contact_name: "",
        address: "",
        phone: "",
        category_id: "",
        website: "",
        type: 1,
    });

    useEffect(() => {
        (async () => {
            // const categories = await AxiosGet('dropdown/categories/4');
            // setDropdown({ categories });
            const fetchedData = await AxiosGet('contact/' + params.id);
            setValues({
                company_name: fetchedData.data.data.company_name,
                primary_contact_name: fetchedData.data.data.primary_contact_name,
                address: fetchedData.data.data.address,
                phone: fetchedData.data.data.phone,
                category_name: fetchedData.data.data.category_id.name,
                website: fetchedData.data.data.website,
                type: fetchedData.data.data.type,
            });
            // console.log(fetchedData)
            setLoading(false);
        })();
    }, [params.id]);

    return (
        <>
            <ContentHeader
                h1={
                    (values && values.type === 1) ?
                        'Vendor Details' :
                        'Customer Details'
                } //'Contact Details'
                buttons={
                    [
                        { url: 'contact/edit/' + params.id, label: 'Edit Contact' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        (values && values.type === 1) ?
                            { url: 'vendors', label: 'Vendors', active: false } :
                            { url: 'customers', label: 'Customers', active: false },
                        // { url: 'contact/' + params.id, label: 'Contact Details', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm px-3 py-3'>
                {
                    (!loading && values !== '') ?
                        <>
                            <ComponentLabelValueRow
                                label='Company Name'
                                value={values.company_name}
                            />
                            <ComponentLabelValueRow
                                label='Primary Contact Name'
                                value={values.primary_contact_name}
                            />
                            <ComponentLabelValueRow
                                label='Address'
                                value={values.address}
                            />
                            <ComponentLabelValueRow
                                label='Phone'
                                value={values.phone}
                            />
                            <ComponentLabelValueRow
                                label='Category'
                                value={values.category_name}
                            />
                            <ComponentLabelValueRow
                                label='Website'
                                value={values.website}
                            />
                        </>
                        : <ComponentLoading />
                }
            </div>
        </>
    );
};
export default ShowContact
