// import React, { useEffect, useRef, useState } from 'react';
// import { MapContainer, TileLayer } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import 'leaflet.heat';
// import 'leaflet.markercluster/dist/MarkerCluster.css';
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

// // Helper to initialize the heat map layer
// const HeatmapLayer = ({ stations, map }) => {
//     useEffect(() => {
//         if (map) {
//             const points = stations.map(station => [
//                 station.lat, station.lon, station.num_bikes_available // Intensity based on bike availability
//             ]);
//             const heatLayer = L.heatLayer(points, {
//                 radius: 20,
//                 blur: 15,
//                 maxZoom: 17,
//             }).addTo(map);

//             return () => {
//                 map.removeLayer(heatLayer);
//             };
//         }
//     }, [stations, map]);

//     return null;
// };

// const StationHeatMap = ({ stations }) => {
//     const mapRef = useRef(null);

//     useEffect(() => {
//         const map = mapRef.current;
//         if (map) {
//             const clusterGroup = createClusterGroup(stations, map);
//             map.addLayer(clusterGroup);
//             map.on('zoomend', () => {
//                 const zoomLevel = map.getZoom();
//                 // Example logic to handle zoom level changes
//             });
//         }
//     }, [stations]); // Dependencies could include stations if they could change

//     return (
//         <MapContainer
//             center={[43.2557, -79.8711]}
//             zoom={13}
//             style={{ height: '500px', width: '100%' }}
//             ref={mapRef}
//             whenCreated={mapInstance => mapRef.current = mapInstance}
//         >
//             <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
//             {mapRef.current && <HeatmapLayer stations={stations} map={mapRef.current} />}
//         </MapContainer>
//     );
// };

// // Function to create a marker cluster group
// function createClusterGroup(stations, map) {
//     const markers = L.markerClusterGroup({
//         iconCreateFunction: (cluster) => {
//             const children = cluster.getAllChildMarkers();
//             let totalBikes = 0;
//             children.forEach(marker => {
//                 totalBikes += marker.options.bikesAvailable;
//             });
//             const size = cluster.getChildCount();
//             let hue = Math.min(120 * (totalBikes / size / 10), 120); // Scale hue from green to red
//             return L.divIcon({
//                 html: `<div style="background-color: hsl(${120 - hue}, 100%, 50%); width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50%; color: white;">${totalBikes}</div>`,
//                 className: '',
//                 iconSize: [40, 40]
//             });
//         }
//     });

//     stations.forEach(station => {
//         const marker = L.marker([station.lat, station.lon], {
//             bikesAvailable: station.num_bikes_available // Store bike count in marker options for easy access
//         });
//         marker.bindPopup(`Station: ${station.name}<br>Available Bikes: ${station.num_bikes_available}<br>Available Docks: ${station.num_docks_available}`);
//         markers.addLayer(marker);
//     });

//     markers.on('clustermouseover', (e) => {
//         const cluster = e.layer;
//         const childMarkers = cluster.getAllChildMarkers();
//         let totalBikes = 0;
//         childMarkers.forEach(marker => {
//             totalBikes += marker.options.bikesAvailable;
//         });
//         const popup = L.popup()
//             .setLatLng(cluster.getLatLng())
//             .setContent(`Total Bikes in Cluster: ${totalBikes}`)
//             .openOn(map);
//     });

//     markers.on('clustermouseout', (e) => {
//         map.closePopup(); // Close the popup when the mouse leaves the cluster
//     });

//     return markers;
// }

// export default StationHeatMap;


// import React, { useEffect, useRef } from 'react';
// import { MapContainer, TileLayer } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import 'leaflet.heat';
// import 'leaflet.markercluster';

// const createHeatmapLayer = (stations) => {
//     return L.heatLayer(
//         stations.map(station => [
//             station.lat,
//             station.lon,
//             station.num_bikes_available // Intensity reflects the number of available bikes
//         ]), { radius: 20, blur: 15 }
//     );
// };

// const createClusterGroup = (stations) => {
//     const markers = L.markerClusterGroup();
//     stations.forEach(station => {
//         const marker = L.marker([station.lat, station.lon]);
//         marker.bindPopup(`Station: ${station.name}<br>Available Bikes: ${station.num_bikes_available}<br>Available Docks: ${station.num_docks_available}`);
//         markers.addLayer(marker);
//     });
//     return markers;
// };

// const StationHeatMap = ({ stations }) => {
//     const mapRef = useRef(null);

//     useEffect(() => {
//         const map = mapRef.current;
//         if (map) {
//             const heatmapLayer = createHeatmapLayer(stations);
//             const clusterGroup = createClusterGroup(stations);
//             map.addLayer(heatmapLayer);
//             map.addLayer(clusterGroup);

//             clusterGroup.on('clustermouseover', (cluster) => {
//                 // Calculate totals for the cluster
//                 let bikesTotal = 0;
//                 let docksTotal = 0;
//                 cluster.layer.getAllChildMarkers().forEach(marker => {
//                     const content = marker.getPopup().getContent();
//                     const bikes = parseInt(content.match(/Available Bikes: (\d+)/)[1], 10);
//                     const docks = parseInt(content.match(/Available Docks: (\d+)/)[1], 10);
//                     bikesTotal += bikes;
//                     docksTotal += docks;
//                 });
//                 const popupContent = `Total Bikes: ${bikesTotal}<br>Total Docks: ${docksTotal}`;
//                 cluster.layer.bindPopup(popupContent).openPopup();
//             });

//             return () => {
//                 map.removeLayer(heatmapLayer);
//                 map.removeLayer(clusterGroup);
//             };
//         }
//     }, [stations]); // React to changes in the stations data

//     return (
//         <MapContainer
//             center={[43.2557, -79.8711]}
//             zoom={13}
//             style={{ height: '500px', width: '100%' }}
//             ref={mapRef}
//             whenCreated={mapInstance => mapRef.current = mapInstance}
//         >
//             <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
//         </MapContainer>
//     );
// };

// export default StationHeatMap;





import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';

const HeatmapLayer = ({ stations, map }) => {
    useEffect(() => {
        if (map) {
            const heatmapLayer = L.heatLayer(
                stations.map(station => [
                    station.lat,
                    station.lon,
                    station.num_bikes_available // Intensity based on the number of bikes available
                ]),
                { radius: 25, blur: 15, maxZoom: 17 }
            ).addTo(map);

            return () => map.removeLayer(heatmapLayer);
        }
    }, [stations, map]);

    return null;
};

const StationHeatMap = ({ stations }) => {
    const mapRef = useRef(null);
    const [zoomLevel, setZoomLevel] = useState(13); // Default zoom level on map load
    const [showMarkers, setShowMarkers] = useState(false); // Flag to control marker visibility

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            map.on('zoomend', () => {
                setZoomLevel(map.getZoom());
            });

            // Toggle marker visibility based on zoom level
            setShowMarkers(map.getZoom() > 15); // Adjust this zoom level as needed

            return () => {
                map.off('zoomend');
            };
        }
    }, []);

    return (
        <MapContainer
            center={[43.2557, -79.8711]}
            zoom={13}
            style={{ height: '400px', width: '100%' }}
            ref={mapRef}
            whenCreated={mapInstance => {
                mapRef.current = mapInstance;
            }}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {showMarkers && zoomLevel > 15 && stations.map(station => ( // Adjust this zoom level as needed
                <Marker
                    key={station.station_id}
                    position={[station.lat, station.lon]}
                >
                    <Popup>
                        Station: {station.name}<br />
                        Available Bikes: {station.num_bikes_available}<br />
                        Available Docks: {station.num_docks_available}
                    </Popup>
                </Marker>
            ))}
            {mapRef.current && <HeatmapLayer stations={stations} map={mapRef.current} />}
        </MapContainer>
    );
};

export default StationHeatMap;
