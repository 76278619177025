import axios from 'axios';
import { useEffect, useState } from 'react';

function useFreeBikeData() {
    const [combinedData, setCombinedData] = useState([]);

    useEffect(() => {
        const fetchStationData = async () => {
            try {
                // Fetch station information and status
                const freeBikeResponse = await axios.get('https://hamilton.socialbicycles.com/opendata/free_bike_status.json');

                const bikeData = freeBikeResponse.data.data.bikes;

                setCombinedData(bikeData);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchStationData();
    }, []);

    return combinedData;
}

export default useFreeBikeData;
