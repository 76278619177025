import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AxiosPost, ComponentInput, ContentHeader, onChange } from '../../Components/Components'

const AddChecklist = () => {
    const navigate = useNavigate();

    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
    });

    function saveChecklist(e) {
        if (true) {
            AxiosPost('checklist/add',
                {
                    name: values.name,
                },
            ).then(result => {
                console.log(result.data.data.id)
                setError('')
                navigate("/checklist/edit/" + result.data.data.id)
            }).catch(err => {
                setNotificationMessage('')
                setError(err.response.data.errors)
                console.log(err.response, '----')
            })
        }

    }
    return (
        <>
            <ContentHeader
                h1='Add checklists'
                buttons={
                    [
                        { url: 'checklists', label: 'All checklists' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'checklists', label: 'checklists', active: false },
                        { url: 'checklist/add', label: 'Add', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <span>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Checklist Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button onClick={
                                (e) => {
                                    saveChecklist(e)
                                }
                            } className="btn btn-primary">Submit</button>
                            {notificationMessage}

                        </div>
                    </span>
                </div>
            </div>
        </>
    )
}

export default AddChecklist