import React, { useEffect, useState } from 'react'
import './ComponentStyling/Popup.css';

function Popup(props) {
    return (props.trigger) ? (
        <div className='popup'>
            <div className='popup-inner'>
                {props.children}
            </div>
        </div>
    ) : ""
}

export default Popup