import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { AxiosGet, ComponentInput, ComponentLoading, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components'
import { Toast } from 'react-bootstrap';

const AddPeople = () => {
    const [loading, setLoading] = useState(true);
    const [dropdown, setDropdown] = useState('');
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [passwordShow, setPasswordShow] = useState(false);


    const initialFormValues = {
        name: "",
        email: "",
        phone: "",
        job_title: "",
        team_id: "",
        hourly_rate: "",
        notes: "",
        user_id: "",
        status: "",
        username: "",
        password: "",
        role: "",
    };

    const [values, setValues] = useState(initialFormValues);

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);

    useEffect(() => {
        (async () => {
            const users = await AxiosGet('users-for-people');
            const teams = await AxiosGet('dropdown/teams');
            setDropdown({ users, teams });
            setLoading(false)
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Add People'
                buttons={
                    [
                        { url: 'people', label: 'People' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'people', label: 'People', active: false },
                        { url: 'people/add', label: 'Add', active: true },
                    ]
                }
            />
            {
                !loading ?
                    <>
                        <div className='border rounded-2 shadow-sm mb-3'>
                            <div className="row p-3 pb-1">
                                <div className='col-12 pb-3'>
                                    <h5>
                                        Basic User Info:
                                    </h5>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='name'
                                            type='text'
                                            displayName='Full Name'
                                            className='form-control'
                                            value={values['name']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='phone'
                                            type='text'
                                            displayName='Contact No.'
                                            className='form-control'
                                            value={values['phone']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                    {/* <div className="mb-3">
                                        <ComponentInput
                                            name='username'
                                            type='text'
                                            displayName='Username'
                                            className='form-control'
                                            value={values['username']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div> */}
                                </div>
                                <div className='col-md-6'>

                                    <div className="mb-3">
                                        <ComponentInput
                                            name='email'
                                            type='email'
                                            displayName='Email'
                                            className='form-control'
                                            value={values['email']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='password'
                                            type={!passwordShow ? 'password' : 'text'}
                                            displayName='Password'
                                            className='form-control'
                                            value={values['password']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                        <div className='form-check pt-2'>
                                            <input className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="showPassword"
                                                onClick={() => setPasswordShow(!passwordShow)}
                                            />
                                            <label className="form-check-label" htmlFor="showPassword">
                                                Show Password
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='border rounded-2 shadow-sm'>
                            <div className="row p-3 pb-2">
                                <div className='col-12 pb-3'>
                                    <h5>
                                        User's Role Info:
                                    </h5>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        {
                                            <ComponentSelect
                                                name='role'
                                                displayName='Role'
                                                className='form-select'
                                                options={[
                                                    { value: 3, label: 'Admin' },
                                                    { value: 2, label: 'Manager' },
                                                    { value: 1, label: 'Supervisor' },
                                                    { value: 0, label: 'Technician' },
                                                ]}
                                                onChange={(e) => onChange(e, values, setValues)}
                                                selected={values['role']}
                                                label='label'
                                                value='value'
                                                error={error}
                                            />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='job_title'
                                            type='text'
                                            displayName='Job Title'
                                            className='form-control'
                                            value={values['job_title']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='mb-3'>
                                        <label htmlFor='team_id' className='form-label fw-semibold'>
                                            Team
                                        </label>
                                        {dropdown.teams &&
                                            <Select
                                                name='team_id'
                                                defaultValue={selectedTeam}
                                                onChange={setSelectedTeam}
                                                options={dropdown.teams.data}
                                                isClearable={true}
                                                isMulti
                                            />
                                        }
                                        <span id='team_id-error' className='error text-danger'>
                                            {error && error['team_id']}
                                        </span>
                                    </div>
                                    <div className="mb-3">
                                        <ComponentInput
                                            name='hourly_rate'
                                            type='text'
                                            displayName='hourly Rate'
                                            className='form-control'
                                            value={values['hourly_rate']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>

                                <div className='col-12 pt-2 pb-4'>
                                    <div className="mb-3">
                                        <ComponentTextarea
                                            name='notes'
                                            type='text'
                                            displayName='Notes'
                                            className='form-control'
                                            value={values['notes']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 pt-2 pb-4'>
                                    <button onClick={
                                        (e) => {
                                            let teams = [];
                                            if (selectedTeam !== null && selectedTeam !== '') {
                                                selectedTeam.map((item) => {
                                                    return (
                                                        teams.push(item.value)
                                                    )
                                                });
                                            }
                                            handleSubmitForStore(
                                                e,
                                                'people/add',
                                                {
                                                    name: values.name,
                                                    email: values.email,
                                                    phone: values.phone,
                                                    job_title: values.job_title,
                                                    team_id: teams.toString(),
                                                    hourly_rate: values.hourly_rate,
                                                    notes: values.notes,
                                                    // user_id: values.user_id,
                                                    username: values.email,
                                                    password: values.password,
                                                    role: values.role,
                                                    status: 1,
                                                },
                                                setError,
                                                setNotificationMessage,
                                                setValues,
                                                initialFormValues
                                            )
                                        }
                                    } className="btn btn-primary">Submit</button>
                                    {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                        <Toast.Header>
                                            <img
                                                src="holder.js/20x20?text=%20"
                                                className="rounded me-2"
                                                alt=""
                                            />
                                            <strong className="me-auto">User</strong>
                                            <small>Added</small>
                                        </Toast.Header>
                                        <Toast.Body>{notificationMessage}</Toast.Body>
                                    </Toast> */}
                                </div>
                            </div>
                        </div>
                    </>
                    : <ComponentLoading />
            }
            {/* /.row */}
        </>
    )
}

export default AddPeople