import { Routes, Route, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { AxiosGet } from './Components/Components';

import Dashboard from './Pages/Dashboard';
//lists
import WorkOrders from './Pages/List/WorkOrders';
import Assets from './Pages/List/Assets';
import Inventories from './Pages/List/Inventories';
import Locations from './Pages/List/Locations';
import Customers from './Pages/List/Customers';
import Vendors from './Pages/List/Vendors';
import Checklists from './Pages/List/Checklists';
import Categories from './Pages/List/Categories';
import Teams from './Pages/List/Teams';
import People from './Pages/List/People';
import Parts from './Pages/List/Parts';


// import Files from './pages/Files';
import RequireAuth from './Pages/Auth/RequireAuth';
import Header from './Components/Layout/Header';
import Sidebar from './Components/Layout/Sidebar';
import Footer from './Components/Layout/Footer';
import AddCategory from './Pages/Add/AddCategory';
import EditCategory from './Pages/Edit/EditCategory';
import AddLocation from './Pages/Add/AddLocation';
import EditLocation from './Pages/Edit/EditLocation';
import AddVendor from './Pages/Add/AddVendor';
import EditVendor from './Pages/Edit/EditVendor';
import AddPeople from './Pages/Add/AddPeople';
import EditPeople from './Pages/Edit/EditPeople';
import AddTeam from './Pages/Add/AddTeam';
import EditTeam from './Pages/Edit/EditTeam';
import ShowPart from './Pages/Show/ShowPart';
import ShowInventoryDetail from './Pages/Show/ShowInventoryDetail';
import AddPart from './Pages/Add/AddPart';
import EditPart from './Pages/Edit/EditPart';
import ShowPartDetail from './Pages/Show/ShowPartDetail';
import AddInventory from './Pages/Add/AddInventory';
import EditInventory from './Pages/Edit/EditInventory';
import AddAsset from './Pages/Add/AddAsset';
import EditAsset from './Pages/Edit/EditAsset';
import ShowAsset from './Pages/Show/ShowAsset';
import ShowPeople from './Pages/Show/ShowPeople';
import ShowLocation from './Pages/Show/ShowLocation';
import EditWorkOrder from './Pages/Edit/EditWorkOrder';
import AddChecklist from './Pages/Add/AddChecklist';
import EditChecklist from './Pages/Edit/EditChecklist';
import ShowProfile from './Pages/Show/ShowProfile';
import EditProfile from './Pages/Edit/EditProfile';
import Unauthorized from './Pages/Unauthorized';
import ShowContact from './Pages/Show/ShowContact';
import { isEmpty } from 'lodash';
import CookieService from './services/CookieService';
import EditCustomer from './Pages/Edit/EditCustomer';
import AddCustomer from './Pages/Add/AddCustomer';
import StationRemoval from './Pages/CustomModules/StationRemoval';
import AddWorkOrder from './Pages/Add/AddWorkOrder';
import ShowCategory from './Pages/Show/ShowCategory';
import ShowChecklist from './Pages/Show/ShowChecklist';
import ShowTeam from './Pages/Show/ShowTeam';
import ShowWorkOrder from './Pages/Show/ShowWorkOrder';
import WorkOrdersSelf from './Pages/List/WorkOrdersSelf';
// import WorkOrderGroups from './Pages/List/WorkOrderGroups';
// import AddWorkOrderGroup from './Pages/Add/AddWorkOrderGroup';
// import EditWorkOrderGroup from './Pages/Edit/EditWorkOrderGroup';


function App() {
	const [access, setAccess] = useState([]);
	const [userDetail, setUserDetail] = useState([]);
	const [error, setError] = useState([]);
	// const [loading, setLoading] = useState(true);
	const components = {
		WorkOrders: <WorkOrders />,
		EditWorkOrder: <EditWorkOrder />,
		AddWorkOrder: <AddWorkOrder />,

		// WorkOrderGroups: <WorkOrderGroups />,
		// EditWorkOrderGroup: <EditWorkOrderGroup />,
		// AddWorkOrderGroup: <AddWorkOrderGroup />,

		Parts: <Parts />,
		EditPart: <EditPart />,
		AddPart: <AddPart />,

		Inventories: <Inventories />,
		EditInventory: <EditInventory />,
		AddInventory: <AddInventory />,

		Teams: <Teams />,
		EditTeam: <EditTeam />,
		AddTeam: <AddTeam />,

		Assets: <Assets />,
		EditAsset: <EditAsset />,
		AddAsset: <AddAsset />,

		People: <People />,
		EditPeople: <EditPeople />,
		AddPeople: <AddPeople />,

		Categories: <Categories />,
		EditCategory: <EditCategory />,
		AddCategory: <AddCategory />,

		Locations: <Locations />,
		EditLocation: <EditLocation />,
		AddLocation: <AddLocation />,

		Checklists: <Checklists />,
		EditChecklist: <EditChecklist />,
		AddChecklist: <AddChecklist />,

		Vendors: <Vendors />,
		EditVendor: <EditVendor />,
		AddVendor: <AddVendor />,

		Customers: <Customers />,
		EditCustomer: <EditCustomer />,
		AddCustomer: <AddCustomer />,

	}

	useEffect(() => {
		if (CookieService.get('access_token') && CookieService.get('access_token') !== 'undefined') {
			AxiosGet('access-user?usage=routes').then(result => {
				setAccess(result.data.components)
				// console.log(result);
			}).catch(err => {
				console.log(err.response)
			});
			AxiosGet('user-detail').then(result => {
				setUserDetail(result.data.data)
				// console.log(result)
				// setLoading(false)
				setError([])
			}).catch(err => {
				setError(err.response)
				console.log(err.response.data)
			})
		}
	}, []);

	return (
		<>
			{!isEmpty(error) && console.log(error)}
			<Header />
			<div id="layoutSidenav">
				<div id="layoutSidenav_nav">
					<Sidebar nav={access} user={userDetail} />
				</div>
				<div id="layoutSidenav_content">
					<main>
						<div className="container-fluid px-4">
							<Routes>
								<Route element={<RequireAuth />}>
									<Route path='/*' element={<Outlet />}>
										<Route index element={<Dashboard />} />
										{
											(access) &&
											access.map((route, i) => {
												// console.log(route)
												return (
													(route.frontend_component !== '') &&
													<Route key={i} path={route.frontend_path} element={components[route.frontend_component]} />
												)
											})
										}

										{/* Show/view Routes */}
										<Route path="part/detail/:id" element={<ShowPartDetail />} />
										<Route path="work-orders-self" element={<WorkOrdersSelf />} />
										<Route path="work-orders/:id" element={<WorkOrders />} />
										<Route path="part/view/:id" element={<ShowPart />} />
										<Route path="inventory/view/:id" element={<ShowInventoryDetail />} />
										<Route path="asset/view/:id" element={<ShowAsset />} />
										<Route path="category/view/:id" element={<ShowCategory />} />
										<Route path="checklist/view/:id" element={<ShowChecklist />} />
										<Route path="team/view/:id" element={<ShowTeam />} />
										<Route path="people/view/:id" element={<ShowPeople />} />
										<Route path="location/view/:id" element={<ShowLocation />} />
										<Route path="contact/view/:id" element={<ShowContact />} />
										<Route path="work-order/show" element={<ShowWorkOrder />} />
										<Route path="profile" element={<ShowProfile />} />

										{/* Some edit Routes */}
										<Route path="profile/edit" element={<EditProfile />} />

										{/* Missing routes */}
										<Route path="*" element={<Unauthorized />} />

										{/* Custom Module routes */}
										<Route path="custom-module/station-removal" element={<StationRemoval />} />

										{/* {console.log(window.location.pathname)} */}

									</Route>
								</Route>
							</Routes>
						</div>
					</main>
					<Footer /> {/*Footer*/}
				</div>
			</div>
		</>
	)
}

export default App;

