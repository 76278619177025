import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="py-3 bg-light mt-3 border-top">
                <div className="container-fluid px-4">
                    <div className="d-flex align-items-center justify-content-between small">
                        <div className="text-muted">Copyright © OperateHub.App 2023</div>
                        <div>
                            <a href="#">Privacy Policy</a>
                            ·
                            <a href="#">Terms &amp; Conditions</a>
                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}
