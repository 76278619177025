import { Link, useNavigate } from "react-router-dom";


const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <article style={{ padding: "100px", height: '88vh' }}>
                        <h1>Page Unaccessable!</h1>
                        <p>
                            Page does not exists or you might not have permissions to access it.<br />
                            Contact your administrator to learn more or go to home page or browse another page.
                        </p>
                        <div className="flexGrow">
                            <button onClick={goBack} className="btn btn-sm btn-primary">Go Back</button>
                        </div>
                        <div className="flexGrow pt-2">
                            <Link to="/">Go to Dashboard</Link>
                        </div>
                    </article>
                </div>
            </div>
        </>
    )
}

export default Unauthorized
