import React from 'react';
import CookieService from '../../services/CookieService';


const Logout = () => {

    CookieService.remove('access_token')
    const logout = true;

    return (
        <>
            {logout ? (
                window.location.replace("/")
            ) : (
                ''
            )}
        </>
    )
}

export default Logout
