import React from 'react'

const NotificationMessage = (props) => {
    return (
        <>
            <span className={`text-${props.type}`}>
                {props.message}
            </span>
        </>
    )
}

export default NotificationMessage