import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AxiosGet, ComponentInput, ComponentLabelValueRow, ComponentLoading, ComponentSelect, ContentHeader } from '../../Components/Components'

const ShowTeam = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        name: "",
        people: "",
    });


    useEffect(() => {
        (async () => {
            const result = await AxiosGet('team/' + params.id);
            setValues({
                name: result.data.data.name,
                people: result.data.data.people,
            });
            console.log(result)
            setLoading(false);
        })();
    }, [params.id]);

    return (
        <>
            <ContentHeader
                h1='Team Details'
                buttons={
                    [
                        { url: 'teams', label: 'All Teams' },
                        { url: 'team/edit/' + params.id, label: 'Edit Team' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'teams', label: 'Teams', active: false },
                        { url: 'team/' + params.id, label: 'Team Details', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm px-3 py-3'>
                {
                    (!loading && values.name !== '') ?
                        <>
                            <div className='row pt-2'>
                                <div className='col-12'>
                                    <h6 className=''>Basic Information</h6>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <ComponentLabelValueRow
                                        label='Team Name'
                                        value={values.name}
                                    />
                                </div>
                            </div>
                            <div className='row pt-2 mt-5'>
                                <div className='col-12'>
                                    <h6 className=''>Team Members</h6>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className='col-sm-12'>
                                    <div className='table-responsive'>
                                        <table className='table table-stripped table-hover'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Job Title</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    values.people && values.people != '' ?
                                                        values.people.map((item, i) => {
                                                            if (item.status && item.status == 1) {
                                                                return <tr key={i}>
                                                                    {console.log(item)}
                                                                    <td>{item.name}</td>
                                                                    <td>{item.job_title}</td>
                                                                </tr>
                                                            }
                                                        }) :
                                                        <tr>
                                                            <td colSpan={3}>None added yet...</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <ComponentLoading />
                    // 'loading...'
                }
            </div>

        </>
    );
};
export default ShowTeam
