import CookieService from '../services/CookieService';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import NotificationMessage from './NotificationMessage';
import imageCompression from 'browser-image-compression';
import * as qs from 'qs';
import toast, { createToast } from 'react-simple-toasts';


// Environment and Universal Constants Start
export const baseUrl = process.env.REACT_APP_BASE_URL + '#/';
// export const baseUrl = process.env.REACT_APP_BASE_URL + '';

export const apiUrl = process.env.REACT_APP_API_URL;

export const assetUrl = process.env.REACT_APP_ASSET_URL;

export const bearerToken = 'Bearer ' + CookieService.get('access_token');
// Environment and Universal Constants End


// Custom helper functions Start 
// const navigate = useNavigate();

export function titleCase(str = '') {
    if (str && str !== '') {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    }
};

export function today() {
    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    var today = year + "-" + month + "-" + day;
    return today
};

export const ContentHeader = ({ h1, buttons, breadcrumbs }) => {
    return (

        <div className='row mb-2 mt-4'>
            <div className='col-sm-6  col-md-8'>
                <ol className='breadcrumb float-left'>
                    {breadcrumbs && breadcrumbs.map(function (item, i) {
                        return (
                            <li
                                key={i}
                                className={
                                    item.active ? 'breadcrumb-item active' : 'breadcrumb-item'
                                }
                            >
                                {!item.active ? (
                                    <Link to={`/${item.url}`}> {item.label} </Link>
                                ) : (
                                    item.label
                                )}
                            </li>
                        );
                    })}
                </ol>
            </div>
            <div className='col-sm-6 col-md-4'>
                {(buttons) && buttons.map(function (item, i) {
                    return (
                        <Link
                            key={i}
                            className='float-end btn btn-sm btn-primary me-2 mb-1'
                            id={i}
                            to={`/${item.url}`}
                            onClick={item.onClick}
                        >
                            {item.label}
                        </Link>
                    );
                })}
            </div>
            <div className='col-12'>
                <h2>{h1 && h1}</h2>
            </div>
        </div>
    );
};

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + ("h:") : "0h:";
    var mDisplay = m > 0 ? m + ("m:") : "0m:";
    var sDisplay = s > 0 ? s + ("s") : "00";
    return hDisplay + mDisplay + sDisplay;
};

export function random_key(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

export const compressImage = async (imageFile, keyName, value, setValue) => {
    var controller = new AbortController();
    try {
        const options = {
            maxSizeMB: 1, // (max file size in MB)
            maxWidthOrHeight: 1920, // (max width or height in pixel)
            useWebWorker: true
        };
        const compressedFile = await imageCompression(imageFile, options);
        setTimeout(function () {
            controller.abort(new Error('I just want to stop'));
        }, 1500);
        // After compression, you can call your callback
        setValue({ ...value, [keyName]: compressedFile })
    } catch (error) {
        console.log(error);
    }
};

// Custom helper functions End


// Loaders Start
export const LoadingSpinner = () => {
    return (
        <>
            <div
                className='spinner-container'
                style={{
                    height: '90vh',
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className='spinner-row'>
                    <div
                        className='spinner-item'
                        style={{
                            padding: 5,
                            height: 50,
                            margin: 10,
                            lineHeight: 50,
                            fontWeight: 'bold',
                            fontSize: '2em',
                            textAlign: 'center',
                        }}
                    >
                        <div className='loader' />
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export const ComponentLoading = (props) => {
    return (
        <>
            {
                (props.dataFound === false) ?
                    <div>Data not found..</div>
                    :
                    <div className='d-flex align-items-center' style={{ height: '50vh' }}>
                        <div className="progress " style={{
                            margin: 'auto',
                            width: '150.8px',
                            height: '16.8px',
                            animation: 'progress-422c3u 3.4s infinite steps(100)'
                        }}></div>
                    </div>
            }
        </>
    );
};
// Loaders End


// Axios Start

export function deleteData(props) {
    const data = qs.stringify({ 'id': props.id });

    AxiosDelete(props.endPoint, data)
        .then(result => {
            props.callback && props.callback(result);
            props.setRefetch && props.setRefetch(new Date().getTime()); // Trigger refetch or update state
        })
        .catch(error => {
            console.error('Error in Delete function:', error.response);
            // Handle the error as needed
            props.setError && props.setError(error.response.data.errors)
        })
}

export function storeData(props) {
    if (props.e != null && props.e !== '') {
        props.e.preventDefault();
    }
    AxiosPost(props.endPoint, props.object).then(result => {
        // console.log(result);
        props.callback && props.callback(result);
        props.setRefetch && props.setRefetch(new Date().getTime()); // Trigger refetch or update state
        props.setError && props.setError('');
        props.resetFormFields && props.resetFormFields([]);
        props.setNotificationMessage && props.setNotificationMessage(<NotificationMessage type="success" message={result.data.message} />)
    }).catch(err => {
        console.log(err.response, 'Error time: ' + today('d-m-Y'))
        let error_message = (!err.response.data.errors && err.response.data.message) ? err.response.data.message : '';
        props.setNotificationMessage && props.setNotificationMessage(<NotificationMessage type="danger" message={error_message} />)
        props.setError && props.setError(err.response.data.errors)
        toast(`${err.response.data.message}`, {
            position: 'bottom-right',
            clickable: true,
            clickClosable: true,
            time: 3000,
            render: message => <div className="mb-3 alert alert-danger" role="alert">
                {message}
            </div>
        })
    })

}

export const useAxios = (configObj) => {
    const { method, endPoint } = configObj;
    const [response, setResponse] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(0);

    const refetch = () => setReload((prev) => prev + 1);

    useEffect(() => {
        //let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                const res = await axios[method.toLowerCase()](
                    apiUrl + endPoint,
                    {
                        // ...requestConfig,
                        headers: {
                            Accept: 'application/json',
                            Authorization: bearerToken,
                        },
                        signal: controller.signal,
                    }
                );
                // console.log(res.data.data);
                setResponse(res.data.data);
            } catch (err) {
                // console.log(err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        // call the function
        fetchData();

        // useEffect cleanup function
        return () => controller.abort();

        // eslint-disable-next-line
    }, [reload]);

    return [response, error, loading, refetch];
};

export function AxiosGet(endPoint, id = '') {
    return axios.get(apiUrl + endPoint + id, {
        headers: {
            'Accept': 'application/json',
            'Authorization': bearerToken
        }
    })
};

export function AxiosPost(endPoint, formdata) {
    return axios.post(apiUrl + endPoint, formdata,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': bearerToken
            }
        })
};

export function AxiosPut(endPoint, formdata) {
    return axios.put(apiUrl + endPoint, formdata,
        {
            headers: {
                'Accept': 'application/json',
                'Authorization': bearerToken
            }
        })
};

export function AxiosDelete(endPoint, formdata) {
    var config = {
        method: 'delete',
        url: apiUrl + endPoint,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': bearerToken,
        },
        data: formdata
    };
    return axios(config);
};
// Axios End


//Custom Form Components Start
export const ComponentLabel = (props) => {
    return (
        <label htmlFor={props.htmlFor} className='form-label fw-semibold'>{props.displayName}</label>

    )
}

export const ComponentSelect = (props) => {
    let now = Date.now();
    let selected = ''
    if (props.selected !== null) {
        selected = props.selected
    }
    return (
        <>
            {
                props.displayName && props.displayName !== '' &&
                <ComponentLabel htmlFor={props.name + now} displayName={props.displayName} />
            }
            <select
                id={props.name + now}
                name={props.name}
                className={props.className}
                value={selected}
                onChange={props.onChange}
                disabled={props.disabled ? true : false}
            >
                <option>Select{!props.noOptionLabel && ' ' + props.displayName}</option>
                {
                    (props.options && props.options !== '' && props.options !== null) &&
                    props.options.map((item, i) =>
                        <option key={i} value={item[props.value]}>{item[props.label]}</option>
                    )
                }
            </select>
            <span id={`${props.name}-error`} className="error text-danger">
                {(props.error) && props.error[props.name]}
            </span>
        </>
    )
}

export const ComponentInput = (props) => {
    let now = Date.now();
    return (
        <>
            {
                props.displayName && props.displayName !== '' &&
                <ComponentLabel htmlFor={props.name + now} displayName={props.displayName} />
            }
            <input
                id={props.name + now}
                name={props.name}
                className={props.className}
                type={props.type}
                placeholder={`Enter ${props.displayName}`}
                value={(props.value !== '' && props.value != null) ? props.value : ''}
                onChange={props.onChange}
                disabled={props.disabled ? true : false}
                readOnly={props.readOnly ? true : false}
            />
            <span id={`${props.name}-error`} className="error text-danger">
                {(props.error) && props.error[props.name]}
            </span>
        </>
    )
}

export const ComponentTextarea = (props) => {
    let now = Date.now();
    return (
        <>
            {
                props.displayName && props.displayName !== '' &&
                <ComponentLabel htmlFor={props.name + now} displayName={props.displayName} />
            }
            <textarea
                rows={props.rows ? props.rows : 2}
                id={props.name + now}
                name={props.name}
                className={props.className}
                type={props.type}
                placeholder={`Enter ${props.displayName}`}
                value={(props.value !== '' && props.value != null) ? props.value : ''}
                onChange={props.onChange}
                disabled={!props.disabled ? false : true}
            />
            <span id={`${props.name}-error`} className="error text-danger">
                {(props.error) && props.error[props.name]}
            </span>
        </>
    )
}

export const ComponentLabelValueRow = (props) => {

    return (
        <>
            {/* {console.log(props.value)} */}
            <div className='row mx-1 py-2 border-bottom'>
                <div className='col-md-4 px-3'>
                    {props.label}
                </div>
                <div className='col-md-8'>
                    {props.valueIsArray && Array.isArray(props.value) && props.value[0] !== null && props.value !== '' ?
                        props.value.map((item, i) => {
                            if (props.isLink) {
                                return (<span key={i}>
                                    <Link to='#'>{item.label}</Link>  {i + 1 < props.value.length && ', '}
                                </span>
                                )
                            }
                            else {
                                return (
                                    <span key={i}>{item.label}</span>
                                )
                            }
                        })
                        : props.value}
                </div>
            </div>
        </>
    )
}

export function onChange(e, values, setValues) {
    if (e.target.type === 'checkbox') {
        setValues({ ...values, [e.target.name]: !values[e.target.name] })
    } else {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
}

export function handleSubmitForUpdate(e = null, endPoint, object, setError = '', setNotificationMessage = '') {
    if (e != null && e !== '') {
        e.preventDefault();
    }
    if (true) {
        AxiosPut(endPoint, object)
            // axios.put(apiUrl + endPoint,
            //     object
            // )
            .then(result => {
                setError !== '' && setError('')
                // setNotificationMessage !== '' && setNotificationMessage(<NotificationMessage type="success" message={result.data.message} />)
                toast(`${result.data.message}`, {
                    position: 'bottom-right',
                    clickable: true,
                    clickClosable: true,
                    time: 3000,
                    render: message => <div className="mb-3 alert alert-success" role="alert">
                        {message}
                    </div>
                })
            }).catch(err => {

                // let error_message = (err.response.data.message) ? err.response.data.message : '';
                // setNotificationMessage !== '' && setNotificationMessage(<NotificationMessage type="danger" message={error_message} />)
                setError !== '' && setError(err.response.data.errors)
                console.log(err.response, 'Error time: ' + today('d-m-Y'))
            })
    }
}

export function handleSubmitForStore(e = null, endPoint, object, setError = '', setNotificationMessage = '', setValues = {}, initialFormValues = '') {
    // console.log(object)
    if (e != null && e !== '') {
        e.preventDefault();
    }
    if (true) {
        AxiosPost(endPoint, object).then(result => {
            // console.log(result);
            setError !== '' && setError('')
            // setNotificationMessage !== '' && setNotificationMessage(<NotificationMessage type="success" message={result.data.message} />)
            if (typeof setValues === "function") {
                // setValues is a function, and you can safely call it
                setValues(initialFormValues);
            }
            toast(`${result.data.message}`, {
                position: 'bottom-right',
                clickable: true,
                clickClosable: true,
                time: 3000,
                render: message => <div className="mb-3 alert alert-success" role="alert">
                    {message}
                </div>
            })
        }).catch(err => {
            // console.log(err)
            // let error_message = (!err.response.data.errors && err.response.data.message) ? err.response.data.message : '';
            // setNotificationMessage !== '' && setNotificationMessage(<NotificationMessage type="danger" message={error_message} />)
            setError !== '' && setError(err.response.data.errors)
            console.log(err.response, 'Error time: ' + today('d-m-Y'))
        })
    }
}

//Custom Form Components End


// DO NOT DELETE THIS, KEEP IT FOR REFERENCE (It can remove specific keys from an object)

// selectedTeam.map(({ label, ...rest }) => ({ ...rest })).map((item, i) => {
//     commaSeparatedTeams.push(item.value)
// }
// )

// DO NOT DELETE THIS, KEEP IT FOR REFERENCE (It can remove specific keys from an object)