import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

// Function to dynamically calculate the gradient for marker icon
const customMarkerIcon = (station) => {
    const totalUnits = station.num_bikes_available + station.num_docks_available;
    const bikesRatio = totalUnits > 0 ? station.num_bikes_available / totalUnits : 0; // Avoid division by zero
    const gradientPercentage = Math.round(bikesRatio * 100);  // Convert the ratio to percentage

    const gradientId = `grad-${station.station_id}`; // Ensure a unique gradient ID for each station

    return L.divIcon({
        className: "custom-marker-icon",
        html: `
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 25 41">
            <defs>
              <linearGradient id="${gradientId}" x1="0%" y1="100%" x2="0%" y2="0%">
                <stop offset="0%" style="stop-color:rgb(0,0,255);stop-opacity:1" />
                <stop offset="${100 - gradientPercentage}%" style="stop-color:rgb(0,0,255);stop-opacity:1" />
                <stop offset="${100 - gradientPercentage}%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
                <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
              </linearGradient>
            </defs>
            <path fill="url(#${gradientId})" stroke="#000" stroke-width="1" d="M12 2c5.5 0 10 4.5 10 10 0 2-0.5 3.5-1.5 5L12 39 3.5 17C2.5 15.5 2 14 2 12 2 6.5 6.5 2 12 2z"/>
          </svg>`,
        iconSize: [30, 40],
        iconAnchor: [15, 40],
        popupAnchor: [0, -34],
        shadowSize: [41, 41]
    });
};

// Your getStationColor function here is fine
function getStationColor(station) {
    // const { num_bikes_available, num_docks_available } = station;
    // let color = 'yellow';

    // if (num_bikes_available === 0) {
    //     color = 'blue'; // Empty
    // } else if (num_docks_available === 0) {
    //     color = 'red'; // Full
    // } else if (num_bikes_available / (num_bikes_available + num_docks_available) > 0.5) {
    //     color = 'green'; // Low bikes
    // } else {
    //     color = 'yellow'; // Healthy
    // }
    // return color;
    const maxBikes = station.num_docks_available + station.num_bikes_available; // Total capacity
    const bikesAvailable = station.num_bikes_available;

    // Ensure division is safe
    const ratio = maxBikes === 0 ? 0 : bikesAvailable / maxBikes;

    // Calculate the color
    const colorStart = [255, 0, 0]; // Red - Full
    const colorEnd = [0, 0, 255]; // Blue - Empty
    const colorCurrent = colorStart.map((start, i) => {
        const end = colorEnd[i];
        return Math.round(start * ratio + end * (1 - ratio));
    });

    return `rgb(${colorCurrent.join(',')})`;
}
// Your StationMap component should look something like this
const StationClusterMap = ({ stations }) => {
    const DisplayMap = () => {
        const map = useMap();

        useEffect(() => {
            const markers = L.markerClusterGroup(); // Initialize the cluster group

            stations.forEach(station => {
                const marker = L.marker([station.lat, station.lon], {
                    title: station.name
                }).bindPopup(`<b>${station.name}</b><br/>Available Bikes: ${station.num_bikes_available}`);

                markers.addLayer(marker); // Add marker to the cluster group
            });

            map.addLayer(markers); // Add the cluster group to the map

            return () => {
                map.removeLayer(markers); // Clean up: remove the cluster group when the component is unmounted
            };
        }, [map, stations]); // Depend on `map` and `stations` to recreate the cluster when they change

        return null;
    };

    return (
        <MapContainer center={[43.2557, -79.8711]} zoom={13} style={{ height: '300px', width: '100%' }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <DisplayMap />
        </MapContainer>
    );
};

export default StationClusterMap;
