import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AxiosGet, AxiosPost, ComponentInput, ComponentTextarea, ContentHeader, onChange, today, useAxios } from '../../Components/Components';
import Select from 'react-select';
// import chroma from 'chroma-js';
// import NotificationMessage from '../../Components/NotificationMessage';
import { useNavigate } from "react-router-dom";
import toast from 'react-simple-toasts';

const AddWorkOrder = () => {
    const navigate = useNavigate();

    const [error, setError] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [dropdown, setDropdown] = useState('');
    const [selectedAsset, setSelectedAsset] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPrimaryWorker, setSelectedPrimaryWorker] = useState('');
    const [selectedAdditionalWorker, setSelectedAdditionalWorker] = useState('');
    // const [selectedChecklistId, setSelectedChecklistId] = useState('');
    // const [checklistItems, setChecklistItems] = useState([]);


    const [userDetail] = useAxios({
        axiosInstance: axios,
        method: 'GET',
        endPoint: 'user-detail',
    });
    const [values, setValues] = useState({
        due_date: today(),
        title: '',
        description: '',
        checklist_id: '',
        asset_id: selectedAsset && selectedAsset.value,
        category_id: selectedCategories && selectedCategories.value,
        priority: 1,
        status: 1,
    });

    // const [checklistValues, setChecklistValues] = useState({
    //     description: "",
    //     type: "",
    //     options: "",
    // });

    // const dot = (color = 'transparent') => ({
    //     alignItems: 'center',
    //     display: 'flex',
    //     ':before': {
    //         backgroundColor: color,
    //         borderRadius: 10,
    //         content: '" "',
    //         display: 'block',
    //         marginRight: 8,
    //         height: 10,
    //         width: 10,
    //     },
    // });
    // const colourStyles = {
    //     control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    //     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //         const color = chroma(data.color);
    //         return {
    //             ...styles,
    //             backgroundColor: isDisabled
    //                 ? undefined
    //                 : isSelected
    //                     ? data.color
    //                     : isFocused
    //                         ? color.alpha(0.1).css()
    //                         : undefined,
    //             color: isDisabled
    //                 ? '#ccc'
    //                 : isSelected
    //                     ? chroma.contrast(color, 'white') > 2
    //                         ? 'white'
    //                         : 'black'
    //                     : data.color,
    //             cursor: isDisabled ? 'not-allowed' : 'default',

    //             ':active': {
    //                 ...styles[':active'],
    //                 backgroundColor: !isDisabled
    //                     ? isSelected
    //                         ? data.color
    //                         : color.alpha(0.3).css()
    //                     : undefined,
    //             },
    //         };
    //     },
    //     input: (styles) => ({ ...styles, ...dot() }),
    //     placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    //     singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    // };

    useEffect(() => {
        (async () => {
            const assignee = await AxiosGet('dropdown/assignee');
            const assets = await AxiosGet('dropdown/assets');
            const categories = await AxiosGet('dropdown/categories/1');
            // const checklists = await AxiosGet('dropdown/checklists');

            setDropdown({ assignee, assets, categories });
        })();
    }, []);

    function saveWorkOrder(e) {
        // setChecklistItems(newTodos);
        // console.log(valueChecklist)
        if (true) {
            AxiosPost('work-order/add',
            {
                due_date: values.due_date,
                title: values.title,
                description: values.description,
                // checklist_id: values.checklist_id,
                asset_id: selectedAsset.value,
                category_id: selectedCategories.value,
                priority: values.priority,
                status: values.status,
                created_by: userDetail.id,
                // priority: values.priority,
                additional_worker: selectedAdditionalWorker,
                primary_worker: selectedPrimaryWorker,
            })
            .then(result => {
                if (result.data.data.id) {

                    // const valueChecklist = checklistItems.map((item) => {
                    //     return { ...item, work_order_id: result.data.data.id };
                    // });
                    // setError('')
                    // AxiosPost('work-order-checklist/add',
                    //     valueChecklist,
                    // ).then(result => {
                    //     // console.log(result)
                    //     setError('')
                    // }).catch(err => {
                    //     setError(err.response.data.errors)
                    //     console.log(err.response, '----')
                    // })

                    navigate("/work-order/edit/" + result.data.data.id)
                } else {
                    console.log(result)
                    toast('Unable to add work order!', {
                        position: 'bottom-right',
                        clickable: true,
                        clickClosable: true,
                        time: 3000,
                        render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-danger" role="alert">
                            {message}
                        </div>
                    })
                }
            }).catch(err => {
                setNotificationMessage('')
                setError(err.response.data.errors)
                console.log(err.response, '----')
            })

        }

    }
    // function addChecklistTasks() {
    //     if (selectedChecklistId && selectedChecklistId !== '' && selectedChecklistId !== 0) {
    //         AxiosGet('checklist/items/' + selectedChecklistId.value).then(result => {
    //             if (typeof checklistItems.find(o => o.checklist_id === result.data.data[0].checklist_id) === "undefined") {
    //                 // Saving previous values in a new constant
    //                 const newStateData = [...checklistItems, ...result.data.data]
    //                 // updating the state
    //                 setChecklistItems(newStateData);
    //             } else {
    //                 toast('Checklist items already added!', {
    //                     position: 'bottom-right',
    //                     clickable: true,
    //                     clickClosable: true,
    //                     time: 3000,
    //                     render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-danger" role="alert">
    //                         {message}
    //                     </div>
    //                 })
    //             }
    //         }).catch(err => {
    //             console.log(err.response, '----')
    //         })
    //     } else {
    //         toast('Please select a checklist..', {
    //             position: 'bottom-right',
    //             clickable: true,
    //             clickClosable: true,
    //             time: 3000,
    //             render: message => <div style={{ zIndex: 99999999 }} className="py-1 mb-3 alert alert-danger" role="alert">
    //                 {message}
    //             </div>
    //         })
    //     }
    //     // console.log(checklistItems)
    // }
    // const deleteById = id => {
    //     setChecklistItems(oldValues => {
    //         return oldValues.filter(checklistItems => checklistItems.id !== id)
    //     })
    // }
    // function addChecklistItem(e) {
    //     // let option = checklistValues.options
    //     // if (checklistValues.type === 1) {
    //     //     option = 'pass,fail,flag'
    //     // } else if (checklistValues.type === 5) {
    //     //     option = 'open,inprogress,complete'
    //     // }

    //     // AxiosPost('checklist-item/add',
    //     //     {
    //     //         'description': checklistValues.description,
    //     //         'checklist_id': params.id,
    //     //         'type': checklistValues.type,
    //     //         'options': checklistValues.options
    //     //     },
    //     // ).then(result => {
    //     //     setRefetch(new Date().getTime())
    //     //     setChecklistValues({
    //     //         'description': '',
    //     //         'type': '',
    //     //         'options': '',
    //     //     })
    //     //     setError('')
    //     // }).catch(err => {
    //     //     setError(err.response.data.errors)
    //     //     console.log(err.response, '----')
    //     // })
    // }

    return (
        <>
            <ContentHeader
                h1='Add Work Order'
                buttons={[{ url: 'work-orders', label: 'Work Orders' }]}
                breadcrumbs={[
                    { url: '', label: 'Dashboard', active: false },
                    { url: 'work-orders', label: 'Work Orders', active: false },
                    { url: 'word-order/add', label: 'Add', active: true },
                ]}
            />
            <div className='border rounded-2 shadow-sm'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link active" id="basic-details-tab" data-bs-toggle="tab" data-bs-target="#basic-details" type="button" role="tab" aria-controls="basic-details" aria-selected="true">Basic Details</button>
                    </li>
                    {/* <li className="nav-item" role="presentation" style={{ marginLeft: "-1px", marginTop: "-1px" }}>
                        <button className="nav-link" id="workorder-checklist-tab" data-bs-toggle="tab" data-bs-target="#workorder-checklist" type="button" role="tab" aria-controls="workorder-checklist" aria-selected="false">Checklist(s)</button>
                    </li> */}
                </ul>
                <div className="tab-content py-3 px-3" id="myTabContent">
                    <div className="tab-pane fade show active" id="basic-details" role="tabpanel" aria-labelledby="basic-details-tab">
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentInput
                                        name='title'
                                        type='text'
                                        displayName='Title'
                                        className='form-control'
                                        value={values['title']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='category_id' className='form-label fw-semibold'>
                                        Category
                                    </label>
                                    {dropdown &&
                                        dropdown.categories !== '' &&
                                        dropdown.categories !== null && (
                                            <Select
                                                name='category_id'
                                                defaultValue={selectedCategories}
                                                onChange={setSelectedCategories}
                                                options={dropdown.categories.data}
                                                isClearable={true}
                                            />
                                        )}
                                    <span id='category_id-error' className='error text-danger'>
                                        {error && error['category_id']}
                                    </span>
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='asset_id' className='form-label fw-semibold'>
                                        Asset
                                    </label>
                                    {dropdown &&
                                        dropdown.assets !== '' &&
                                        dropdown.assets !== null && (
                                            <Select
                                                name='asset_id'
                                                defaultValue={selectedAsset}
                                                onChange={setSelectedAsset}
                                                options={dropdown.assets.data}
                                                isClearable={true}
                                            />
                                        )}
                                    <span id='asset_id-error' className='error text-danger'>
                                        {error && error['asset_id']}
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='mb-3'>
                                    <ComponentTextarea
                                        name='description'
                                        type='text'
                                        rows={8}
                                        displayName='Description'
                                        className='form-control'
                                        value={values['description']}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        error={error}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            userDetail && userDetail.role > 1 &&
                            <div className='row border-top mt-3 pt-4'>
                                    <div className='col-sm-6'>
                                    <div className='mb-3'>
                                        <label htmlFor='assignee_id' className='form-label fw-semibold'>
                                            Primary Technician
                                        </label>
                                        {dropdown &&
                                                dropdown.assignee !== '' &&
                                                dropdown.assignee !== null && (
                                                <Select
                                                    name='assignee_id'
                                                    defaultValue={selectedPrimaryWorker}
                                                    onChange={setSelectedPrimaryWorker}
                                                    options={dropdown.assignee.data}
                                                    isClearable={true}
                                                />
                                            )}
                                        <span id='assignee_id-error' className='error text-danger'>
                                            {error && error['assignee_id']}
                                        </span>
                                    </div>
                                    <div className='mb-3'>
                                        <label htmlFor='additional_assignee_id' className='form-label fw-semibold'>
                                            Additional Technicians
                                        </label>
                                        {dropdown &&
                                                dropdown.assignee !== '' &&
                                                dropdown.assignee !== null && (
                                                <Select
                                                    name='additional_assignee_id'
                                                    defaultValue={selectedAdditionalWorker}
                                                    onChange={setSelectedAdditionalWorker}
                                                    options={dropdown.assignee.data}
                                                    isOptionDisabled={(option) =>
                                                        selectedPrimaryWorker &&
                                                        option.value === selectedPrimaryWorker.value
                                                    }
                                                    isClearable={true}
                                                    isMulti
                                                />
                                            )}
                                        <span id='additional_assignee_id-error' className='error text-danger'>
                                            {error && error['additional_assignee_id']}
                                        </span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='mb-3'>
                                        <label htmlFor='priority' className='form-label fw-semibold'>
                                            Priority
                                        </label>
                                        <div>
                                            <input type="radio" value={1} className="btn-check" name="priority" id="priority-low" autoComplete="off" defaultChecked />
                                            <label className="btn btn-outline-primary me-2" htmlFor="priority-low">
                                                Low
                                            </label>

                                            <input type="radio" value={2} className="btn-check" name="priority" id="priority-medium" autoComplete="off" />
                                            <label className="btn btn-outline-warning me-2" htmlFor="priority-medium">
                                                Medium
                                            </label>

                                            <input type="radio" value={3} className="btn-check" name="priority" id="priority-high" autoComplete="off" />
                                            <label className="btn btn-outline-danger" htmlFor="priority-high">
                                                High
                                            </label>
                                        </div>
                                        <span id='priority-error' className='error text-danger'>
                                            {error && error['priority']}
                                        </span>
                                    </div>
                                    <div className='mb-3'>
                                        <ComponentInput
                                            name='due_date'
                                            type='date'
                                            displayName='Due Date'
                                            className='form-control'
                                            value={values['due_date']}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            error={error}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* <div className="tab-pane fade" id="workorder-checklist" role="tabpanel" aria-labelledby="workorder-checklist-tab">
                        <div className='row d-flex justify-content-start align-items-center'>
                            <div className='col-sm-10'>
                                <div className='mb-3'>
                                    <label htmlFor='checklist_id' className='form-label fw-semibold'>
                                        Checklist
                                    </label>
                                    {dropdown &&
                                        dropdown.checklists !== '' &&
                                        dropdown.checklists !== null && (
                                            <Select
                                                name='checklist_id'
                                                defaultValue={selectedChecklistId}
                                                onChange={setSelectedChecklistId}
                                                options={dropdown.checklists.data}
                                                isClearable={true}
                                            />
                                        )}
                                    <span id='checklist_id-error' className='error text-danger'>
                                        {error && error['checklist_id']}
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <button
                                    onClick={
                                        () => {

                                            addChecklistTasks()
                                        }
                                    }
                                    className='btn btn-primary mt-3'
                                >
                                    Add Tasks/Checklist
                                </button>
                            </div>
                        </div>
                        <div className="row mt-3 py-2">
                            <div className='col-12'>
                                <div className='table-responsive'>
                                    <table className='table table-stripped table-hover'>
                                        <thead>
                                            <tr>
                                                <th width='60%'>Description</th>
                                                <th width='30%'>Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                checklistItems !== '' ?
                                                    checklistItems.map((item, i) => {
                                                        let type = '';
                                                        if (item.type === 1) {
                                                            type = 'Inspection'
                                                        } else if (item.type === 2) {
                                                            type = 'Number'
                                                        } else if (item.type === 3) {
                                                            type = 'Text'
                                                        } else if (item.type === 4) {
                                                            type = 'Multiple Checkbox'
                                                        } else {
                                                            type = 'Status'
                                                        }
                                                            return <tr key={i}>
                                                                <td>{item.description}</td>
                                                                <td>
                                                                    {type}
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className='ms-3'
                                                                        onClick={() => deleteById(item.id)}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        <i className='fas fa-trash-alt text-danger'></i>
                                                                    </span>
                                                                </td>
                                                            </tr>

                                                    }) :
                                                    <tr>
                                                        <td colSpan={3}>No items added yet...</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='row pb-3 px-3'>
                    <div className='col-12'>
                        <button
                            onClick={
                                (e) => {

                                    saveWorkOrder(e)
                                }
                            }
                            className='btn btn-primary'
                        >
                            Submit
                        </button>
                        {/* {notificationMessage} */}
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddWorkOrder;
