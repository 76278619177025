import React, { useEffect, useState } from 'react'
// Import the hook you created
import useCombinedStationData from '../Components/useCombinedStationData';
import StationMap from '../Components/StationMap';
import StationClusterMap from '../Components/StationClusterMap';
import StationHeatMap from '../Components/StationHeatMap';
import StationChart from '../Components/StationChart';
import StationTimeSeries from '../Components/StationTimeSeries';
import BikeChart from '../Components/BikeChart';
import useFreeBikeData from '../Components/useFreeBikeData';

const Dashboard = () => {



    const stations = useCombinedStationData();
    const freeBikes = useFreeBikeData();
    // console.log(freeBikes);
    return (
        <>
            <div className="container-fluid1">
                <h5 className='title mt-3'>
                    Analytics and Visualizations &nbsp;
                    <small className='fs-6'>
                        (Data from Open/Public endpoints except Trips)
                    </small>
                </h5>
                <div className='row my-2'>
                    <div className='col-lg-3 col-12 py-3'>
                        <div className='border p-3 shadow rounded'>
                            <h6>Stations Status</h6>
                            <StationChart stations={stations} />
                        </div>
                    </div>
                    <div className='col-lg-3 col-12 py-3'>
                        <div className='border p-3 shadow rounded'>
                            <h6>Bike Status</h6>
                            <BikeChart stations={stations} freeBike={freeBikes} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 py-3'>
                        <div className='border p-3 shadow rounded'>
                            <h6>Bikes Availability Heat Map</h6>
                            <StationHeatMap stations={stations} />
                        </div>
                    </div>
                </div>
                <div className='row my-2'>
                    <div className='col-12 py-3'>
                        <div className='border p-3 shadow rounded'>
                            <h6>
                                Trips Over Time &nbsp;
                                <small>(Dummy Data)</small>
                            </h6>
                            <StationTimeSeries />
                        </div>
                    </div>
                </div>
                <div className='row my-2 mt-3'>
                    <div className='col-md-6 col-12 py-3'>
                        <div className='border p-3 shadow rounded'>
                            <h6>Stations (Empty/Full)</h6>
                            <StationMap stations={stations} />
                        </div>
                    </div>
                    <div className='col-md-6 col-12 py-3'>
                        <div className='border p-3 shadow rounded'>
                            <h6>Stations Clusters</h6>
                            <StationClusterMap stations={stations} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Dashboard