import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AxiosGet, ComponentInput, ComponentLabelValueRow, ComponentLoading, ComponentSelect, ContentHeader } from '../../Components/Components'

const ShowCategory = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        name: "",
        type: "",
    });


    useEffect(() => {
        (async () => {
            const result = await AxiosGet('category/' + params.id);
            setValues({
                name: result.data.data.name,
                typeName: result.data.data.typeName,
            });
            // console.log(result)
            setLoading(false);
        })();
    }, [params.id]);

    return (
        <>
            <ContentHeader
                h1='Category Details'
                buttons={
                    [
                        { url: 'categories', label: 'All Categories' },
                        { url: 'category/edit/' + params.id, label: 'Edit Category' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'categories', label: 'Categories', active: false },
                        { url: 'category/' + params.id, label: 'Category Details', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm px-3 py-3'>
                {
                    (!loading && values !== '') ?
                        <>
                            <div className='row pt-2'>
                                <div className='col-12'>
                                    <h6 className=''>Basic Information</h6>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <ComponentLabelValueRow
                                        label='Category Name'
                                        value={values.name}
                                    />
                                    <ComponentLabelValueRow
                                        label='Category Type'
                                        value={values.typeName}
                                    />
                                </div>
                            </div>
                        </> :
                        <ComponentLoading />
                    // 'loading...'
                }
            </div>

        </>
    );
};
export default ShowCategory
