import React, { useState, useEffect } from 'react'
import { AxiosGet, ComponentInput, ContentHeader, ComponentSelect, onChange, handleSubmitForStore } from '../../Components/Components'
import { Toast } from 'react-bootstrap';

const AddCustomer = () => {
    const [dropdown, setDropdown] = useState('');
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");

    const initialFormValues = {
        company_name: "",
        primary_contact_name: "",
        address: "",
        phone: "",
        category_id: "",
        website: "",
        type: 2,
    };
    const [values, setValues] = useState(initialFormValues);

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);

    useEffect(() => {
        (async () => {
            const categories = await AxiosGet('dropdown/categories/4');
            setDropdown({ categories });
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Add Customer'
                buttons={
                    [
                        { url: 'customers', label: 'All Customers' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'customers', label: 'Customers', active: false },
                        { url: 'customer/add', label: 'Add', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <form>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='company_name'
                                    type='text'
                                    displayName='Customer Name'
                                    className='form-control'
                                    value={values['company_name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='primary_contact_name'
                                    type='text'
                                    displayName='Contact Name'
                                    className='form-control'
                                    value={values['primary_contact_name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='address'
                                    type='text'
                                    displayName='Address'
                                    className='form-control'
                                    value={values['address']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='phone'
                                    type='text'
                                    displayName='Contact Number'
                                    className='form-control'
                                    value={values['phone']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                {dropdown &&
                                    dropdown.categories != '' &&
                                    dropdown.categories != null && (
                                        <ComponentSelect
                                            name='category_id'
                                            displayName='Category'
                                            className='form-control'
                                            options={dropdown.categories.data}
                                            onChange={(e) => onChange(e, values, setValues)}
                                            selected={values['category_id']}
                                            label='label'
                                            value='value'
                                            error={error}
                                        />
                                    )}
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='website'
                                    type='text'
                                    displayName='Website'
                                    className='form-control'
                                    value={values['website']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>

                        </div>
                        <div className="col-12">
                            <button onClick={
                                (e) => {
                                    handleSubmitForStore(
                                        e,
                                        'contact/add',
                                        {
                                            company_name: values.company_name,
                                            primary_contact_name: values.primary_contact_name,
                                            address: values.address,
                                            phone: values.phone,
                                            category_id: values.category_id,
                                            website: values.website,
                                            type: 2,
                                        },
                                        setError,
                                        setNotificationMessage,
                                        setValues,
                                        initialFormValues
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""
                                    />
                                    <strong className="me-auto">Customer</strong>
                                    <small>Added</small>
                                </Toast.Header>
                                <Toast.Body>{notificationMessage}</Toast.Body>
                            </Toast> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddCustomer