import { Link } from "react-router-dom"

export const ComponentAction = (props) => {
    return (
        <>
            {/* {console.log(props.data.can)} */}
            {
                (props.data && props.data.can) ?
                    <>

                        {
                            (props.data.can.edit) &&
                            <Link className='mx-2' to={`/${props.target}/edit/${props.data.id}`}>
                                <i className="far fa-edit text-primary"></i>
                            </Link>
                        }
                        {/* {
                            (props.data.can.delete) &&
                            <Link className='' to={`/${props.target}/delete/${props.data.id}`}>
                                <i className="fas fa-trash-alt text-danger"></i>
                            </Link>
                        } */}
                        {
                            (!props.data.can.edit && !props.data.can.delete) &&
                            <Link className='' to={`/${props.target}/view/${props.data.id}`}>
                                <i className="fas fa-eye text-primary"></i>
                            </Link>
                        }
                    </> : ''
            }
        </>
    )
}