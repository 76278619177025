import axios from 'axios';
import { useEffect, useState } from 'react';

function useCombinedStationData() {
    const [combinedData, setCombinedData] = useState([]);

    useEffect(() => {
        const fetchStationData = async () => {
            try {
                // Fetch station information and status
                const infoResponse = await axios.get('https://hamilton.socialbicycles.com/opendata/station_information.json');
                const statusResponse = await axios.get('https://hamilton.socialbicycles.com/opendata/station_status.json');

                const infoData = infoResponse.data.data.stations;
                const statusData = statusResponse.data.data.stations;

                // Create a map of station statuses for quick lookup
                const statusMap = new Map(statusData.map(item => [item.station_id, item]));

                // Combine the information
                const combined = infoData.map(info => ({
                    ...info,
                    ...statusMap.get(info.station_id)
                }));

                setCombinedData(combined);
            } catch (error) {
                console.error('Failed to fetch station data:', error);
            }
        };

        fetchStationData();
    }, []);

    return combinedData;
}

export default useCombinedStationData;
