import { useState, useEffect } from "react";
import CookieService from "../../services/CookieService";
import { baseUrl } from "../Components";
const Header = () => {
    const [isClosed, setIsClosed] = useState(false)
    useEffect(() => {
        document.body.classList.toggle('sb-sidenav-toggled', isClosed);
        CookieService.set("showSidebar", isClosed, '/');
    }, [isClosed])

    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            {/* Navbar Brand*/}
            <a className="navbar-brand ps-3" href="/">Operate Hub</a>
            {/* Sidebar Toggle*/}
            <button
                className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
                id="sidebarToggle"
                onClick={() => setIsClosed(!isClosed)}>
                <i className="fas fa-bars" />
            </button>
            {/* Navbar Search*/}
            {/* <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                <div className="input-group">
                    <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search" /></button>
                </div>
            </form> */}
            {/* Navbar*/}
            <div className="w-100">
                <ul className="navbar-nav float-end ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw" /></a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href={baseUrl + 'profile'}>Profile</a></li>
                            {/* <li><a className="dropdown-item" href={baseUrl + ''}>Settings</a></li> */}
                            {/* <li><a className="dropdown-item" href="#!">Activity Log</a></li> */}
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href={baseUrl + 'logout'}>Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </div>

        </nav>

    )
}
export default Header
