import axios from "axios";
import { apiUrl } from "../Components/Components";
import CookieService from "./CookieService";
import UserProfile from "./userProfile";


const expiresAt = 60 * 24;

class AuthServiceClass {
  async doUserLogin(credentials) {
    try {
      const response = await axios.post(apiUrl + 'login', credentials);
      if (response.status === 200) {
        // console.log(response.status)
        UserProfile.setToken(response.data.access_token);
      }
      return response.data;
    } catch (error) {
      // console.error("Error", error.response);
      return error.response;
    }
  }
  handleLoginSuccess(response, remember) {
    if (!remember) {
      const options = { path: "/" };
      CookieService.set("access_token", response.access_token, options);
      UserProfile.setExpiresAt(response.expires_at);
      return true;
    }

    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const options = { path: "/", expires: date };
    CookieService.set("access_token", response.access_token, options);
    UserProfile.setExpiresAt(date);
    return true;
  }
}

const AuthService = new AuthServiceClass();

export default AuthService;