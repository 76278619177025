var UserProfile = (function () {
    var expiresAt = "";
    var token = "";
    // var isLoggedin = false;

    var getToken = function () {
        return token;    // Or pull this from cookie/localStorage
    };

    var setToken = function (access_token) {
        token = access_token;
        // Also set this in cookie/localStorage
    };

    var getExpiresAt = function () {
        return expiresAt;    // Or pull this from cookie/localStorage
    };

    var setExpiresAt = function (expires_at) {
        expiresAt = expires_at;
        // Also set this in cookie/localStorage
    };

    return {
        getToken: getToken,
        setToken: setToken,
        getExpiresAt: getExpiresAt,
        setExpiresAt: setExpiresAt,
    }

})();

export default UserProfile;