import React, { useEffect, useState } from 'react'
import { ComponentInput, ComponentSelect, ContentHeader, handleSubmitForStore, onChange } from '../../Components/Components'
import { Toast } from 'react-bootstrap';

const AddCategory = () => {

    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");
    const initialFormValues = {
        name: "",
        type: "",
    };
    const [values, setValues] = useState(initialFormValues);

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);

    return (
        <>
            <ContentHeader
                h1='Add categories'
                buttons={
                    [
                        { url: 'categories', label: 'All Categories' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'categories', label: 'Categories', active: false },
                        { url: 'category/add', label: 'Add', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <form>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Category Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentSelect
                                    name='type'
                                    displayName='Category Type'
                                    className='form-select'
                                    options={[
                                        { value: 1, label: 'Work Order' },
                                        { value: 2, label: 'Asset' },
                                        { value: 3, label: 'Parts' },
                                        { value: 4, label: 'Contact' },
                                        { value: 5, label: 'Contact Detail' },
                                    ]}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    selected={values['type']}
                                    label='label'
                                    value='value'
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button onClick={
                                (e) => {
                                    handleSubmitForStore(
                                        e,
                                        'category/add',
                                        {
                                            name: values.name,
                                            type: values.type,
                                        },
                                        setError,
                                        setNotificationMessage,
                                        setValues,
                                        initialFormValues
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {/* {notificationMessage} */}
                            {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""
                                    />
                                    <strong className="me-auto">Category</strong>
                                    <small>Added</small>
                                </Toast.Header>
                                <Toast.Body>{notificationMessage}</Toast.Body>
                            </Toast> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddCategory