import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ContentHeader, ComponentInput, ComponentSelect, AxiosGet, handleSubmitForUpdate, onChange, ComponentTextarea, AxiosPost, apiUrl, AxiosDelete, ComponentLabelValueRow, ComponentLoading } from '../../Components/Components'
import * as qs from 'qs';

const ShowChecklist = () => {

    const params = useParams()
    const [error, setError] = useState([]);
    const [refetch, setRefetch] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [checklistItems, setChecklistItems] = useState([]);

    const [values, setValues] = useState({
        name: "",
    });
    const [checklistValues, setChecklistValues] = useState({
        description: "",
        type: "",
        options: "",
    });

    useEffect(() => {
        AxiosGet('checklist/' + params.id).then(result => {
            setValues({
                name: result.data.data.name,
            });
            setChecklistItems(result.data.data.checklist_item);
            setLoading(false)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, [refetch]);

    return (
        <>
            <ContentHeader
                h1='Checklist Details'
                buttons={
                    [
                        { url: 'checklists', label: 'All Checklist' },
                        { url: 'checklist/edit/' + params.id, label: 'Edit Checklist' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'checklists', label: 'Checklist', active: false },
                        { url: 'checklist/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm px-3 py-3'>
                {
                    (!loading && values !== '') ?
                        <>
                            <div className='row pt-2'>
                                <div className='col-12'>
                                    <h6 className=''>Basic Information</h6>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <ComponentLabelValueRow
                                        label='Category Name'
                                        value={values.name}
                                    />
                                </div>
                            </div>
                            <div className='row pt-2 mt-5'>
                                <div className='col-12'>
                                    <h6 className=''>Checklist Items/Tasks</h6>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className='col-sm-12'>
                                    <div className='table-responsive'>
                                        <table className='table table-stripped table-hover'>
                                            <thead>
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Type</th>
                                                    <th>Options</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !loading && checklistItems && checklistItems != '' ?
                                                        checklistItems.map((item, i) => {
                                                            let type = '';
                                                            if (item.type == 1) {
                                                                type = 'Inspection'
                                                            } else if (item.type == 2) {
                                                                type = 'Number'
                                                            } else if (item.type == 3) {
                                                                type = 'Text'
                                                            } else if (item.type == 4) {
                                                                type = 'Boolean (Yes/No)'
                                                            } else if (item.type == 6) {
                                                                type = 'Multiple Options'
                                                            } else {
                                                                type = 'Status'
                                                            }
                                                            let options = '';
                                                            if (item.type == 1) {
                                                                options = 'Pass, Fail or Flag'
                                                            } else if (item.type == 2) {
                                                                options = 'Number (123456789)'
                                                            } else if (item.type == 3) {
                                                                options = 'Text (abc)'
                                                            } else if (item.type == 4) {
                                                                options = 'Yes/No'
                                                            } else {
                                                                options = 'Open, In Progress, On Hold or Complete'
                                                            }
                                                            if (true) {
                                                                return <tr key={i}>
                                                                    <td>{item.description}</td>
                                                                    <td width={150}>
                                                                        {type}
                                                                    </td>
                                                                    <td width={200}>
                                                                        {options}
                                                                    </td>
                                                                </tr>
                                                            }
                                                        }) :
                                                        <tr>
                                                            <td colSpan={3}>No tasks added yet...</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </> :
                        <ComponentLoading />
                    // 'loading...'
                }
            </div>
        </>
    )
}
export default ShowChecklist
