// import React, { useState, useEffect } from 'react';

// // Select Filter Component
// const SelectFilter = ({ options, onSelectChange }) => {
//     return (
//         <select onChange={e => onSelectChange(e.target.value)}>
//             {options.map(option => (
//                 <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//         </select>
//     );
// };


// // Export components if needed
// export default SelectFilter;


// import "regenerator-runtime/runtime";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";

const SelectFilter = ({ column, values, search, setSearch }) => {

    const columnFilterValue = search[column.id] || '';
    const [value, setValue] = useState(search[column.id]);
    // const onChange = useAsyncDebounce((value) => {
    //     // Update the search state for this column only
    //     setSearch((prevSearch) => ({
    //         ...prevSearch,
    //         [column.id]: value
    //     }));
    // }, 300);
    let options = '';
    (column.preFilteredRows) &&
        column.preFilteredRows.map((item, i) => {

            // console.log((item.isArray));
            Object.entries(item).map(entry => {
                // let key = entry[0];
                // let value = entry[1];
                if (entry[0] === 'original') {
                    if (!options.includes(entry[1].team_name) && entry[1].team_name !== null) {
                        options += entry[1].team_name;
                    }
                    // console.log(entry[1].team_name);
                }
            });
        })
// console.log(options);
    return (
        <div className='input-group input-group-sm'>
            <select onChange={(e) => {
                // setValue(e.target.value);
                // onChange(e.target.value);
            }
            }>
                {/* {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))} */}
            </select>
            {/* <input
                className="form-control w-100 text-black"
                placeholder={"Search " + column.Header}
                type={column.inputType === 'number' ? column.inputType : 'text'}
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                min={0}
            /> */}
        </div>
    );

};

export default SelectFilter;


