import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ContentHeader, ComponentInput, ComponentSelect, AxiosGet, handleSubmitForUpdate, onChange } from '../../Components/Components'
import { Toast } from 'react-bootstrap';


const EditCategory = () => {

    const params = useParams()
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [values, setValues] = useState({
        name: "",
        type: "",
        status: "",
    });

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);

    useEffect(() => {
        (async () => {
            const result = await AxiosGet('category/' + params.id);
            setValues({
                name: result.data.data.name,
                type: result.data.data.type,
                status: result.data.data.status,
            });
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Edit Category'
                buttons={
                    [
                        { url: 'categories', label: 'All Categories' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'categories', label: 'Categories', active: false },
                        { url: 'category/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>
                <div className="row p-3">
                    <form>
                        <div className="col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Category Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentSelect
                                    name='type'
                                    displayName='Category Type'
                                    className='form-select'
                                    options={[
                                        { value: 1, label: 'Work Order' },
                                        { value: 2, label: 'Asset' },
                                        { value: 3, label: 'Parts' },
                                        { value: 4, label: 'Contact' },
                                        { value: 5, label: 'Contact Detail' },
                                    ]}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    selected={values['type']}
                                    label='label'
                                    value='value'
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentSelect
                                    name='status'
                                    displayName='Category Status'
                                    className='form-select'
                                    options={[
                                        { value: 1, label: 'Active' },
                                        { value: 0, label: 'Inactive' },
                                    ]}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    selected={values['status']}
                                    label='label'
                                    value='value'
                                    error={error}
                                />
                            </div>
                        </div>
                        <div className='col-12 pt-3'>
                            <button onClick={
                                (e) => {
                                    handleSubmitForUpdate(
                                        e,
                                        'category/edit',
                                        {
                                            id: params.id,
                                            name: values.name,
                                            type: values.type,
                                            status: values.status,
                                        },
                                        setError,
                                        setNotificationMessage
                                    )
                                }
                            } className="btn btn-primary">Submit</button>
                            {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""
                                    />
                                    <strong className="me-auto">Category</strong>
                                    <small>Updated</small>
                                </Toast.Header>
                                <Toast.Body>{notificationMessage}</Toast.Body>
                            </Toast> */}

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditCategory