import React, { useState } from 'react';
import { ComponentInput, baseUrl, handleSubmitForUpdate, onChange } from '../../Components/Components';


const ResetPassword = () => {
    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");


    return (
        <>
            {success ? (
                console.log('success')
                // window.location.replace("/")
            ) : (
                <>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header">
                                        <h3 className="text-center font-weight-light mt-4">
                                                Operate Hub
                                                <small className="x-small d-block text-center font-weight-light mb-4">Assets & Operations Management</small>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <form onSubmit={handleFormSubmit}> */}
                                        <div className="small mb-3 text-muted">Enter your email address (associated with the system) and your new password.</div>
                                        <div className="form-floating mb-3">
                                            <div className="mb-3">
                                                <ComponentInput
                                                    name='email'
                                                    type='text'
                                                    displayName='Email'
                                                    className='form-control'
                                                    value={values['email']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <div className="mb-3">
                                                <ComponentInput
                                                    name='password'
                                                    type={!passwordShow ? 'password' : 'text'}
                                                    displayName='Password'
                                                    className='form-control'
                                                    value={values['password']}
                                                    onChange={(e) => onChange(e, values, setValues)}
                                                    error={error}
                                                />
                                                <div className='form-check pt-2'>
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        defaultValue
                                                        id="showPassword"
                                                        onClick={() => setPasswordShow(!passwordShow)}
                                                    />
                                                    <label className="form-check-label" htmlFor="showPassword">
                                                        Show Password
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 pt-2 pb-4'>
                                            <button onClick={
                                                (e) => {
                                                    handleSubmitForUpdate(
                                                        e,
                                                        'reset-password',
                                                        {
                                                            token: window.location.hash.split('?')[1].split('=')[1],
                                                            email: values.email,
                                                            password: values.password,
                                                        },
                                                        setError,
                                                        setNotificationMessage
                                                    )
                                                }
                                            } className="btn btn-primary" id="reset-password-button">Reset Password</button>
                                            {notificationMessage}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <a className="small" href={baseUrl + '/login'}>Return to login</a >
                                        </div>

                                    </div>
                                        <div className="card-footer text-center py-3 d-none">
                                            <div className="small"><a href="#">Powered by OperateHub.App</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default ResetPassword
