import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { Link } from 'react-router-dom';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';

const Vendors = () => {

    // React-table start
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'company_name',
                Header: 'Company Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/contact/view/${cell.row.values.id}`}>
                            {cell.value}
                        </Link>
                    </>
                )
            },
            {
                accessor: 'primary_contact_name',
                Header: 'Contact Name'
            },
            {
                accessor: 'address',
                Header: 'Address'
            },
            {
                accessor: 'phone',
                Header: 'Phone'
            },
            {
                accessor: 'category_id.name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'website',
                Header: 'Website'
            },
            {
                id: 'action',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    (cell.row.original.can) ?
                        <>

                            {
                                (cell.row.original.can.edit) &&
                                <Link className='mx-2' to={`/vendor/edit/${cell.row.original.id}`}>
                                    <i className="far fa-edit text-primary"></i>
                                </Link>
                            }
                            {
                                (!cell.row.original.can.edit && !cell.row.original.can.delete) &&
                                <Link className='' to={`/contact/view/${cell.row.original.id}`}>
                                    <i className="fas fa-eye text-primary"></i>
                                </Link>
                            }
                        </> : ''

                )
            }
        ],
        []
    )

    useEffect(() => {
        AxiosGet('vendors').then(result => {
            setData(result.data.data);
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Vendors'
                buttons={
                    [
                        { url: 'vendor/add', label: 'Add Vendor' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'vendors', label: 'Vendors', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                    />
                </div>
            </div>
        </>
    )
}
export default Vendors;
