import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../Components/CustomTable/CustomReactTable';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';


const Teams = () => {

    // React-table start
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Team Name',
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <ComponentAction
                            data={cell.row.original}
                            target='team'
                        />
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        AxiosGet('teams').then(result => {
            setData(result.data.data);
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Teams'
                buttons={
                    [
                        { url: 'team/add', label: 'Add Team' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'teams', label: 'Teams', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                    />
                </div>
            </div>
        </>
    )
}
export default Teams;
