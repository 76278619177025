import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { Link } from 'react-router-dom';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { cookie } from '../../services/CookieService';
import { isEmpty } from 'lodash';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';

const Inventories = () => {

    // React-table start
    const [data, setData] = useState([]);
    const [canPart, setCanPart] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [refetchColumns, setRefetchColumns] = useState(0);

    function inStock(row) {
        return (row.quantity_total - row.quantity_consumed);
    }

    const columns = React.useMemo(
        () => [
            {
                accessor: 'part_name',
                Header: 'Part Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/part/detail/${cell.row.original.part_id}`}>
                            {cell.value}
                        </Link>
                    </>
                )
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
            {
                accessor: 'barcode',
                Header: 'Barcode',
            },
            {
                accessor: 'location_name',
                Header: 'Location',
                Filter: SelectColumnFilter,
            },
            {
                accessor: inStock,
                Header: 'In Stock',
            },
            {
                accessor: 'category_name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'avg_cost',
                Header: 'Cost(Avg)',
                Cell: ({ cell }) => {
                    return parseFloat(cell.value).toFixed(2);
                }

            },
            {
                accessor: 'stock_status',
                Header: 'Status',
                Filter: SelectColumnFilter,
                Cell: ({ cell }) => {
                    let labelClass = '';
                    if (cell.row.values.stock_status.includes("In")) {
                        labelClass = 'bg-success';
                    } else if (cell.row.values.stock_status.includes("Out")) {
                        labelClass = 'bg-danger';
                    } else if (cell.row.values.stock_status.includes("Low")) {
                        labelClass = 'bg-warning';
                    }
                    return <span className={'badge ' + labelClass}>{cell.row.values.stock_status}</span>
                }
            },
            {
                id: 'action',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    (cell.row.original.can) ?
                        <>
                            {/* {console.log(cell.row)} */}
                            {
                                (cell.row.original.can.edit) &&
                                <Link className='mx-2' to={`/inventory/view/${cell.row.original.id}`}>
                                    Manage <i class="fa-solid fa-cart-flatbed"></i>
                                </Link>
                            }
                            {
                                (!cell.row.original.can.edit && !cell.row.original.can.delete) &&
                                <Link className='' to={`/inventory/view/${cell.row.original.id}`}>
                                    <i className="fas fa-eye text-primary"></i>
                                </Link>
                            }
                        </> : ''
                )
            },
        ],
        []
    )

    useEffect(() => {
        // cookie based column visibility start
        let cookies = cookie.getAll();
        if (cookies && !isEmpty(cookies) && cookies !== null) {
            let hiddenColumnNames = [];
            Object.keys(cookies).map((item, i) => {
                if (item.includes(window.location.hash + '-column|') && (cookies[item] === 'true')) {
                    hiddenColumnNames.push(item.split("|")[1]);
                    // console.log(item);
                    // console.log(cookies[item]);
                }
            });
            setHiddenColumns(hiddenColumnNames);
        }
        // cookie based column visibility end

        AxiosGet('inventory').then(result => {
            setData(result.data.data);
            setCanPart(result.data.can_part)
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Inventory'
                buttons={
                    canPart && canPart.add &&
                    [
                        { url: 'part/add', label: 'Add Part' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'inventory', label: 'Inventory', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                        toggleColumnVisiblity={true}
                        setRefetchColumns={setRefetchColumns}
                        hiddenColumns={hiddenColumns}
                    />
                </div>
            </div>
        </>
    );
};
export default Inventories
