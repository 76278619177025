import React, { useState, useEffect } from 'react';
import { ContentHeader, AxiosGet } from '../../Components/Components';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../Components/CustomTable/CustomReactTable';
import { cookie } from '../../services/CookieService';
import { isEmpty } from 'lodash';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';

const Locations = () => {

    // React-table start
    const [data, setData] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [refetchColumns, setRefetchColumns] = useState(0);

    function parentLocation(row) {
        if (row.parent_location_id !== null && row.parent_location_id !== 0 && row.parent_location_id !== '') {
            return <Link className='mx-2' to={`/location/view/${row.parent_location_id.id}`}>
                {row.parent_location_id.name}
            </Link>
        } else {
            return 'N/A';
        }
    }

    const columns = React.useMemo(
        () => [
            // {
            //     accessor: 'reference_id',
            //     Header: 'Location Id',
            //     Cell: ({ cell }) => (
            //         <>
            //             <Link className='mx-2' to={`/location/view/${cell.row.values.id}`}>
            //                 {cell.value}
            //             </Link>
            //         </>
            //     )
            // },
            {
                accessor: 'name',
                Header: 'Location Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/location/view/${cell.row.values.id}`}>
                            {cell.value}
                        </Link>
                    </>
                )
            },
            {
                accessor: 'address',
                Header: 'Street Address',
            },
            {
                accessor: 'longitude',
                Header: 'Longitude',
            },
            {
                accessor: 'latitude',
                Header: 'Latitude',
            },
            {
                accessor: 'map_address',
                Header: 'Google Map Link',
                Cell: ({ cell }) => (
                    <>
                        <a className='mx-2' href={cell.value} target='_blank'>
                            {cell.value}
                        </a>
                    </>
                )
            },
            {
                accessor: parentLocation,
                Header: 'Parent Location',
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <ComponentAction
                            data={cell.row.original}
                            target='location'
                        />
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {
        // cookie based column visibility start
        let cookies = cookie.getAll();
        if (cookies && !isEmpty(cookies) && cookies !== null) {
            let hiddenColumnNames = [];
            Object.keys(cookies).map((item, i) => {
                if (item.includes(window.location.hash + '-column|') && (cookies[item] === 'true')) {
                    hiddenColumnNames.push(item.split("|")[1]);
                    // console.log(item);
                    // console.log(cookies[item]);
                }
            });
            setHiddenColumns(hiddenColumnNames);
        }
        // cookie based column visibility end
        AxiosGet('locations').then(result => {
            setData(result.data.data);
            // console.log(result.data.data)
        }).catch(err => {
            console.log(err)
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Locations'
                buttons={
                    [
                        { url: 'location/add', label: 'Add Location' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'location', label: 'Locations', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                        toggleColumnVisiblity={true}
                        setRefetchColumns={setRefetchColumns}
                        hiddenColumns={hiddenColumns}
                    />
                </div>
            </div>
        </>
    );
};
export default Locations
