import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import DataTableView from '../../Components/CustomTable/DataTableView';
// import { isEmpty } from 'lodash';
import { cookie } from '../../services/CookieService';
import SelectFilter from '../../Components/CustomTable/Filters/SelectFilter';
import { AxiosGet, ComponentInput, ComponentSelect, ContentHeader, apiUrl, bearerToken, onChange } from '../../Components/Components';
import axios from 'axios';
import _ from 'lodash';

const WorkOrdersSelf = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [dropdown, setDropdown] = useState('');
    const [data, setData] = useState({
        current_page: '',
        data: [],
        from: '',
        last_page: '',
        last_page_url: '',
        links: '',
        next_page_url: '',
        path: '',
        per_page: '',
        prev_page_url: '',
        to: '',
        total: '',
    });
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState({});
    const [sorting, setSorting] = useState([]);


    const fetchData = () => {
        // console.log(search);
        // console.log(apiUrl);
        setLoading(true);
        axios.get(apiUrl + 'work-orders/self/params', {
            params: {
                page: pageIndex + 1,
                pageSize: pageSize,
                filters: search,
                sorting: JSON.stringify(sorting), // Include sortBy in the request
            },
            headers: {
                'Accept': 'application/json',
                'Authorization': bearerToken
            }
        }).then(response => {
            // let category = response.data
            // setDropdown({ category });
            const result = response.data.data;
            setData({
                current_page: result.current_page,
                data: result.data,
                from: result.from,
                last_page: result.last_page,
                last_page_url: result.last_page_url,
                links: result.links,
                next_page_url: result.next_page_url,
                path: result.path,
                per_page: result.per_page,
                prev_page_url: result.prev_page_url,
                to: result.to,
                total: result.total,
            });
            setPageSize(result.per_page)
            // console.log(response)
            setLoading(false);
        }).catch(error => {
            console.log(error);
        });
    };

    // In your columns definition
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                inputType: 'number',
            },
            {
                Header: 'Title',
                accessor: 'title'
            },
            {
                Header: 'Description',
                accessor: 'description'
            },
            {
                id: 'asset_name',
                Header: 'Asset',
                accessor: 'asset.name',
                Cell: ({ cell }) => (
                    (cell.row.original.asset_id) ?
                        <Link className='mx-2' to={`/asset/view/${cell.row.original.asset_id}`}>
                            {cell.row.original.asset_name}
                        </Link> :
                        <>
                            N/A
                        </>
                )
            },
            {
                id: 'category_name',
                Header: 'Category',
                accessor: 'category_name',
                // Filter: SelectFilter,
                Filter: (props) => <SelectFilter {...props} search={search} setSearch={setSearch} />,
                Cell: ({ cell }) => (
                    (cell.row.original.category_name) ?
                        <span>
                            {cell.row.original.category_name}
                        </span> :
                        <>
                            N/A
                        </>
                )
            },
            {
                id: 'priority',
                Header: 'Priority',
                accessor: 'priority',
                Cell: ({ cell }) => {
                    let iconClass = '';
                    let colorClass = '';
                    if (cell.value === 'Low') {
                        iconClass = 'fas fa-thermometer-empty';
                        colorClass = 'text-success';
                    } else if (cell.value === 'Medium') {
                        iconClass = 'fas fa-thermometer-half';
                        colorClass = 'text-warning';
                    } else if (cell.value === 'High') {
                        iconClass = 'fas fa-thermometer-full';
                        colorClass = 'text-danger';
                    }
                    return <span className={colorClass}>
                        <i className={iconClass}></i> {cell.value}
                    </span>
                },
            },
            {
                id: 'people_names',
                accessor: 'people_names',
                Header: 'Assignee',
                Cell: ({ cell }) => {
                    const assignees = cell.row.original.work_order_assignee;
                    if (assignees && assignees.length > 0) {
                        return assignees.map((item, i) => (
                            <span key={i}>
                                <Link to={`/people/view/${item.people_id}`}>
                                    {item.people.name}
                                </Link>
                                {(i < assignees.length - 1) ? ', ' : ''}
                            </span>
                        ));
                    } else {
                        return 'Null';
                    }
                },
            },
            {
                id: 'total_time_consumed',
                Header: 'Time',
                accessor: 'total_time_consumed'
            },
            {
                id: 'team_name',
                Header: 'Team',
                accessor: 'team_name',
                Cell: ({ cell }) => (
                    (cell.row.original.team_name) ?
                        <span>
                            {cell.row.original.team_name}
                        </span> :
                        <>
                            N/A
                        </>
                )
            },
            {
                accessor: 'due_date',
                Header: 'Due Date',
            },
            {
                id: 'status',
                accessor: 'status',
                Header: 'Status',
                // Filter: SelectColumnFilter,
                Cell: ({ cell }) => {
                    let labelClass = '';
                    if (cell.value === 'Open') {
                        labelClass = 'bg-success';
                    } else if (cell.value === 'On hold') {
                        labelClass = 'bg-warning';
                    } else if (cell.value === 'In progress') {
                        labelClass = 'bg-primary';
                    } else if (cell.value === 'Complete') {
                        labelClass = 'bg-secondary text-white';
                    }
                    return <span className={'badge ' + labelClass}>{cell.value}</span>
                }
            },
            {
                id: 'action',
                Header: 'Action',
                // disableFilters: true,
                Cell: ({ cell, item = cell.row.original, target = 'work-order' }) => (
                    (item.can) ?
                        <>
                            {
                                (item.can.edit) &&
                                <Link className='mx-2' to={`/${target}/edit/${item.id}`}>
                                    <i className="far fa-edit text-primary"></i>
                                </Link>
                            }
                            {
                                (item.can.delete) &&
                                <Link className='' to={`/${target}/delete/${item.id}`}>
                                    <i className="fas fa-trash-alt text-danger"></i>
                                </Link>
                            }
                            {
                                (!item.can.edit && !item.can.delete) &&
                                <Link className='' to={`/${target}/view/${item.id}`}>
                                    <i className="fas fa-eye text-primary"></i>
                                </Link>
                            }
                        </> : ''
                )
            },
        ],
        []
    );


    useEffect(() => {
        setLoading(true)
        const debouncedFetch = _.debounce(() => {
            fetchData();
        }, 500); // 500 ms delay

        debouncedFetch(search, data);
        // setLoading(false);
    }, [/*search,*/ pageIndex, pageSize])

    return (
        // !loading ?
        <div className=''>
            <ContentHeader
                h1='Work Orders'
                buttons={
                    [
                        { url: 'work-order/add', label: 'Add Work Order' },
                        { url: 'work-orders/all', label: 'All Work Orders' },
                        // { url: 'work-orders', label: 'My Work Orders'/*, onClick: (e) => setHiddenColumns([...hiddenColumns, 'workorderAssignee']) */},
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'work-order', label: 'Work Orders', active: true },
                    ]
                }
            />
            {/* {console.log(data)} */}
            <div className='row pb-3'>
                <div className='col-12 mb-2'>
                    <h4>Filters</h4>
                </div>
                <div className='col-md-2'>
                    <div className='mb-3'>
                        <ComponentInput
                            name='description'
                            type='text'
                            displayName='Description'
                            className='form-control'
                            value={search['description']}
                            onChange={(e) => onChange(e, search, setSearch)}
                            error={error}
                        />
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className='mb-3'>
                        <ComponentInput
                            name='category_name'
                            type='text'
                            displayName='Category'
                            className='form-control'
                            value={search['category_name']}
                            onChange={(e) => onChange(e, search, setSearch)}
                            error={error}
                        />
                    </div>
                </div>
                {/* <div className='col-md-2'>
                    <div className='mb-3'>
                        <ComponentInput
                            name='people_names'
                            type='text'
                            displayName='Assignees'
                            className='form-control'
                            value={search['people_names']}
                            onChange={(e) => onChange(e, search, setSearch)}
                            error={error}
                        />
                    </div>
                </div> */}
                <div className='col-md-2'>
                    <div className='mb-3'>
                        <ComponentInput
                            name='team_name'
                            type='text'
                            displayName='Team'
                            className='form-control'
                            value={search['team_name']}
                            onChange={(e) => onChange(e, search, setSearch)}
                            error={error}
                        />
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className='mb-3'>
                        <ComponentSelect
                            name='status'
                            displayName='Status'
                            className='form-select'
                            options={[
                                { value: '', label: 'All' },
                                { value: 'Open', label: 'Open' },
                                { value: 'On hold', label: 'On hold' },
                                { value: 'In progress', label: 'In progress' },
                                { value: 'Complete', label: 'Complete' },
                            ]}
                            onChange={(e) => onChange(e, search, setSearch)}
                            selected={search['status'] ? search['status'] : ''}
                            label='label'
                            value='value'
                            error={error}
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <button onClick={(e) => { fetchData(search, data) }} className="btn btn-primary">Search</button>
                </div>
            </div>
            <DataTableView
                tableData={data.data}
                columns={columns}
                toggleColumnVisiblity={true}
                toggleExportData={true}
                loading={loading}
            />

            {/* Render pagination controls */}
            {/* Page size selector */}
            <div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                    <div className='col-form-label col-form-label-sm pe-2 mt-1'>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {data.last_page}
                        </strong>{' '}
                        | Per page:
                    </div>
                    <div className='pe-2' style={{ maxWidth: '100px' }}>
                        <input
                            type='number'
                            className='form-control'
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                cookie.set('default-page-size', Number(e.target.value), { path: '' })
                            }}
                            style={{ /*marginLeft: '10px', */ width: '60px' }}
                        />
                    </div>
                    <div>
                        <select
                            className='form-control'
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                cookie.set('default-page-size', Number(e.target.value), { path: '' })
                            }}
                        >
                            {[10, 25, 50, 75].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-form-label col-form-label-sm ps-2 mt-1'>
                        Total rows{' '}
                        <strong>
                            {data.total}
                        </strong>
                    </div>
                </div>
                <div className='bd-highlight'>
                    <nav aria-label='Page navigation example'>
                        <ul className='pagination justify-content-end'>
                            <li className='page-item'>
                                <button
                                    className={`page-link ${((pageIndex) === 0) && 'disabled'}`}
                                    onClick={e => {
                                        setPageIndex(0)
                                    }}
                                    disabled={((pageIndex) === 0) ? true : false}

                                >
                                    {'<<'}
                                </button>
                            </li>
                            <li className='page-item'>
                                <button
                                    className={`page-link ${((pageIndex) === 0) && 'disabled'}`}
                                    onClick={e => { setPageIndex(pageIndex - 1) }} disabled={((pageIndex) === 0) ? true : false}
                                >
                                    Previous
                                </button>
                            </li>
                            <li className='page-item'>
                                <button
                                    className={`page-link ${((pageIndex + 1 - data.last_page) === 0) && 'disabled'}`}
                                    onClick={e => { setPageIndex(pageIndex + 1) }} disabled={((pageIndex + 1 - data.last_page) === 0) ? true : false}
                                >
                                    Next
                                </button>
                            </li>
                            <li className='page-item'>
                                <button
                                    className={`page-link ${((pageIndex + 1 - data.last_page) === 0) && 'disabled'}`}
                                    onClick={e => {
                                        setPageIndex(data.last_page - 1)
                                    }} disabled={((pageIndex + 1 - data.last_page) === 0) ? true : false}

                                >
                                    {'>>'}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        // : <ComponentLoading />
    );
};

export default WorkOrdersSelf;