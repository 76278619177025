import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl, bearerToken, ContentHeader } from '../../Components/Components'

const ShowPart = () => {

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [partDetails, setPartDetails] = useState({
        id: "",
        name: "",
        description: "",
        category: "",
        barcode: "",
        status: "",
    });


    useEffect(() => {
        axios.get(apiUrl + 'part/detail/' + params.id, {
            headers: {
                'Accept': 'application/json',
                'Authorization': bearerToken
            }
        }).then(result => {
            setPartDetails({
                id: result.data.part.id,
                name: result.data.part.name,
                category: result.data.part.category.name,
                barcode: result.data.part.barcode,
                description: result.data.part.description,
                status: result.data.part.status,
            });
            setLoading(false)
            console.log(result.data.part)
        }).catch(err => {
            // console.log(err.response, '----')
        })
    }, [params.id]);



    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <ContentHeader
                    h1='Part Details'
                    buttons={
                        [
                            { url: 'part/edit/' + partDetails['id'], label: 'Edit Part' },
                            { url: 'inventory/add', label: 'Add Inventory' },
                        ]
                    }
                    breadcrumbs={
                        [
                            { url: '', label: 'Dashboard', active: false },
                            { url: 'parts', label: 'Parts', active: false },
                            { url: 'parts', label: 'Part Details', active: true },
                        ]
                    }
                />
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            (!loading && partDetails !== '') ?
                                                <>
                                                    <div className='row px-2'>
                                                        <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                            Id
                                                        </div>
                                                        <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                            {partDetails.id}
                                                        </div>
                                                    </div>
                                                    <div className='row px-2'>
                                                        <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                            Name
                                                        </div>
                                                        <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                            {partDetails.name}
                                                        </div>
                                                    </div>
                                                    <div className='row px-2'>
                                                        <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                            Description
                                                        </div>
                                                        <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                            {partDetails.description}
                                                        </div>
                                                    </div>
                                                    <div className='row px-2'>
                                                        <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                            Category
                                                        </div>
                                                        <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                            {partDetails.category}
                                                        </div>
                                                    </div>
                                                    <div className='row px-2'>
                                                        <div className='col-sm-2 px-3 py-2 border border-bottom-0 border-right-0'>
                                                            Barcode
                                                        </div>
                                                        <div className='col-sm-10 py-2 border border-bottom-0 border-left-0'>
                                                            {partDetails.barcode}
                                                        </div>
                                                    </div>
                                                    <div className='row px-2'>
                                                        <div className='col-sm-2 px-3 py-2 border  border-right-0'>
                                                            Status
                                                        </div>
                                                        <div className='col-sm-10 py-2 border border-left-0'>
                                                            {partDetails.status === 1 ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </div>
                                                </> : 'loading...'
                                        }
                                    </div>
                                </div>

                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}

            </div >

        </div >
    );
};
export default ShowPart