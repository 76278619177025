import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';

const StationChart = ({ stations }) => {
    // Calculate station availability data
    const data = {
        empty: 0,
        almostEmpty: 0,
        full: 0,
        almostFull: 0,
        moderate: 0
    };

    stations.forEach(station => {
        const { num_bikes_available, num_docks_available } = station;
        if (num_bikes_available === 0) {
            data.empty += 1;// Empty
        } else if (num_docks_available === 0) {
            data.full += 1; // Full
        } else if (num_bikes_available / (num_bikes_available + num_docks_available) < 0.9) {
            data.almostEmpty += 1; // Almost empty
        } else if (num_bikes_available / (num_bikes_available + num_docks_available) > 0.6) {
            data.almostFull += 1; // Almost full
        } else {
            data.moderate += 1; // Moderate
        }
    });

    const totalStations = stations.length;

    const chartData = [
        { name: 'Empty', value: data.empty, percentage: ((data.empty / totalStations) * 100).toFixed(2) },
        { name: 'Almost Empty', value: data.almostEmpty, percentage: ((data.almostEmpty / totalStations) * 100).toFixed(2) },
        { name: 'Full', value: data.full, percentage: ((data.full / totalStations) * 100).toFixed(2) },
        { name: 'Almost Full', value: data.almostFull, percentage: ((data.almostFull / totalStations) * 100).toFixed(2) },
    ];

    const COLORS = ['#99B177', '#D0B574', '#837F79', '#B83D46']; // Colors for the pie chart

    // Custom tooltip formatter function
    const renderCustomTooltip = ({ payload }) => {
        if (payload && payload.length) {
            const { name, value, percentage } = payload[0].payload;
            return (
                <div style={{ backgroundColor: 'white', padding: '5px' }}>
                    <div><strong>{name}</strong></div>
                    <div>Count: {value} ({percentage}%)</div>
                </div>
            );
        }
        return null;
    };

    return (
        <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie data={chartData} cx="50%" cy="50%" outerRadius="50%" fill="#8884d8" dataKey="value" label>
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={renderCustomTooltip} />
                    <Legend align="center" verticalAlign="bottom" layout="horizontal" />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default StationChart;



// import React from 'react';
// import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';

// const StationChart = ({ stations }) => {
//     // Calculate station availability data
//     const data = {
//         empty: 0,
//         almostEmpty: 0,
//         full: 0,
//         almostFull: 0,
//         moderate: 0
//     };

//     stations.forEach(station => {
//         const { num_bikes_available, num_docks_available } = station;
//         if (num_bikes_available === 0) {
//             data.empty += 1;// Empty
//         } else if (num_docks_available === 0) {
//             data.full += 1; // Full
//         } else if (num_bikes_available / (num_bikes_available + num_docks_available) < 0.9) {
//             data.almostEmpty += 1; // Almost empty
//         } else if (num_bikes_available / (num_bikes_available + num_docks_available) > 0.6) {
//             data.almostFull += 1; // Almost full
//         } else {
//             data.moderate += 1; // Moderate
//         }
//     });

//     const totalStations = stations.length;

//     const chartData = [
//         { name: 'Empty', value: data.empty, percentage: ((data.empty / totalStations) * 100).toFixed(2) },
//         { name: 'Almost Empty', value: data.almostEmpty, percentage: ((data.almostEmpty / totalStations) * 100).toFixed(2) },
//         { name: 'Full', value: data.full, percentage: ((data.full / totalStations) * 100).toFixed(2) },
//         { name: 'Almost Full', value: data.almostFull, percentage: ((data.almostFull / totalStations) * 100).toFixed(2) },
//     ];

//     const COLORS = ['#99B177', '#D0B574', '#837F79', '#B83D46']; // Colors for the pie chart

//     // Custom tooltip formatter function
//     const renderCustomTooltip = ({ payload }) => {
//         if (payload && payload.length) {
//             const { name, value, percentage } = payload[0].payload;
//             return (
//                 <div style={{ backgroundColor: 'white', padding: '5px' }}>
//                     <div><strong>{name}</strong></div>
//                     <div>Count: {value} ({percentage}%)</div>
//                 </div>
//             );
//         }
//         return null;
//     };

//     return (
//         <PieChart width={400} height={400} className='mx-auto'>
//             <Pie data={chartData} cx={160} cy={200} outerRadius={120} fill="#8884d8" dataKey="value" label paddingAngle={1}>
//                 {chartData.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//             </Pie>
//             <Tooltip content={renderCustomTooltip} />
//             <Legend align="left" verticalAlign="bottom" layout="horizontal" wrapperStyle={{ paddingTop: 0 }} />

//         </PieChart>
//     );
// };

// export default StationChart;

