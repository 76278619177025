import React, { useRef, useContext, forwardRef, useMemo, useState, useEffect } from 'react';
import {
    useTable,
    // usePagination,
    // useFilters,
    // useGlobalFilter,
    // useSortBy,
    useRowSelect,
} from 'react-table';
import { useExportData } from "react-table-plugins";
// import axios from 'axios';
// import { bearerToken } from '../Components';
import { cookie } from '../../services/CookieService';
import ColumnFilter from "./Filters/ColumnFilter";
import _ from 'lodash';
import { isEmpty } from 'lodash';
import Papa from "papaparse";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Accordion from 'react-bootstrap/Accordion';
import { AccordionContext, Card, useAccordionButton } from 'react-bootstrap';
import { ComponentLoading } from '../Components';


/**
 * MAIN REACT TABLE COMPONENT START
*/
const DataTableView = ({
    columns,
    toggleColumnVisiblity = false,
    toggleExportData = false,
    tableData,
    loading
}) => {
    const [data, setData] = useState([]);

    // Default column properties
    const defaultColumn = useMemo(
        () => ({
            // Filter: (props) => <ColumnFilter {...props} search={search} setSearch={setSearch} />,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        allColumns,
        getToggleHideAllColumnsProps,
        exportData, // export table data
        setHiddenColumns,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            manualFilters: true,
            getExportFileBlob,
        },
        useRowSelect,
        useExportData,
    );

    useEffect(() => {

        // cookie based column visibility start
        let cookies = cookie.getAll();
        if (cookies && !isEmpty(cookies) && cookies !== null) {
            let hiddenColumnNames = [];
            Object.keys(cookies).map((item, i) => {
                if (item.includes(window.location.hash + '-column|') && (cookies[item] === 'true')) {
                    hiddenColumnNames.push(item.split("|")[1]);
                }
            });
            setHiddenColumns(hiddenColumnNames);
        }
        // cookie based column visibility end

        setData(tableData);

    }, [tableData]); // Ensure this runs only when pageIndex changes



    /**
     * TOGGLE HIDDEN COLUMN START
     */

    //table custom buttons accordion start
    function ContextAwareToggle({ children, eventKey, callback, className }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;
        let arrow = isCurrentEventKey ? ' ▲' : ' ▼';
        let label = children + arrow;
        return (
            <button
                type="button"
                className={`no-focus border-0 ${className}`}
                style={{
                    color: '#000000',
                    backgroundColor: '#f9fafb',
                    opacity: isCurrentEventKey ? '0.8' : '1'
                }}
                onClick={decoratedOnClick}
            >
                {label}
            </button>
        );
    }
    //table custom buttons accordion end

    // Column visiblity start
    const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <div className='cb action'>
                <label className='d-none'>
                    <input type='checkbox' ref={resolvedRef} {...rest} />
                    <span> All</span>
                </label>
            </div>
        );
    });
    // Column visiblity end

    // Export table start
    function getExportFileBlob({ columns, data, fileType, fileName }) {
        if (fileType === "csv") {
            // CSV example
            const headerNames = columns.map((col) => col.exportValue);
            const csvString = Papa.unparse({ fields: headerNames, data });
            return new Blob([csvString], { type: "text/csv" });
        }
        //PDF example
        if (fileType === "pdf") {
            const headerNames = columns.map((column) => column.exportValue);
            const doc = new JsPDF();
            doc.autoTable({
                head: [headerNames],
                body: data,
                margin: { top: 20 },
                styles: {
                    minCellHeight: 9,
                    halign: "left",
                    valign: "center",
                    fontSize: 11,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }

        // Other formats goes here
        return false;
    }
    // Export table end

    function showHideColmunsWithCookies(e, column) {
        cookie.set(window.location.hash + '-column|' + column.id, column.isVisible, { path: '' })
    }
    /**
     * TOGGLE HIDDEN COLUMN END
     */


    return (
        <div className='table-responsive'>
            {
                (toggleColumnVisiblity || toggleExportData) && (
                    <div className='mb-4'>
                        <Accordion>
                            <Card className='border-0 p-0'>
                                <Card.Header className='bg-transparent border-0 p-0'>
                                    {
                                        toggleColumnVisiblity && (
                                            <span className='me-2'>
                                                <ContextAwareToggle eventKey="0" className="btn btn-primary btn-sm">Show/Hide Columns</ContextAwareToggle>
                                            </span>
                                        )
                                    }
                                    {
                                        toggleExportData && (
                                            <span>
                                                <ContextAwareToggle eventKey="1" className="btn btn-primary btn-sm">Export</ContextAwareToggle>
                                            </span>
                                        )
                                    }
                                </Card.Header>
                                {
                                    toggleColumnVisiblity && (
                                        <Accordion.Collapse eventKey="0" className='border-0'>
                                            <Card.Body className='p-0 pt-2 px-2'>
                                                <>
                                                    <div>
                                                        <IndeterminateCheckbox
                                                            {...getToggleHideAllColumnsProps()}
                                                        />
                                                    </div>
                                                    {/* Loop through columns data to create checkbox */}
                                                    <div className='row'>
                                                        {allColumns.map((column) => (
                                                            <div className='cb action col-sm-3' key={column.id}>
                                                                <div className='form-check form-switch'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        id={column.Header + column.id}
                                                                        type='checkbox'
                                                                        // onClick={(e) => console.log(column.isVisible)}
                                                                        onClick={(e) => showHideColmunsWithCookies(e, column)}
                                                                        {...column.getToggleHiddenProps()}
                                                                    />{' '}
                                                                    {
                                                                        // console.log(column.id)
                                                                    }
                                                                    <label
                                                                        className='form-check-label'
                                                                        htmlFor={column.Header + column.id}
                                                                    >
                                                                        {column.Header}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    )
                                }
                                {
                                    toggleExportData && (
                                        <Accordion.Collapse eventKey="1" className='border-0'>
                                            <Card.Body className='p-0 pt-2 px-2'>
                                                <div>
                                                    <button className='btn btn-sm btn-info me-2'
                                                        onClick={() => { exportData("csv", true); }}>Export as CSV</button>
                                                    {/* <button className='btn btn-sm btn-secondary me-2'
                                                        onClick={() => { exportData("csv", false); }}>Current View as CSV</button> */}
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    )
                                }
                            </Card>
                        </Accordion>
                    </div>
                )
            }
            <table
                className='table table-hover table-bordered table-sm'
                {...getTableProps()}>
                <thead className='border-light sticky-top'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {
                    !loading ?
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, i) => {
                                            return <td {...cell.getCellProps()} key={i}>{cell.render('Cell')}</td>;
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                        :
                        <tbody>
                            <tr>
                                <td colSpan={headerGroups[0].headers.length ? headerGroups[0].headers.length : 1}>
                                    <ComponentLoading />
                                </td>
                            </tr>
                        </tbody>
                }
            </table>
        </div>
    );
};

export default DataTableView;
