import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { AxiosGet, ComponentInput, ComponentSelect, ComponentTextarea, ContentHeader, handleSubmitForUpdate, onChange } from '../../Components/Components'
import { Toast } from 'react-bootstrap';


const EditLocation = () => {
    const params = useParams()
    const [dropdown, setDropdown] = useState('');
    const [loading, setLoading] = useState('');
    const [error, setError] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState("");

    const [values, setValues] = useState({
        name: "",
        reference_id: "",
        address: "",
        map_address: "",
        longitude: "",
        latitude: "",
        has_parent: false,
        is_deleted: false,
        status: '',
        parent_location_id: "",
    });

    const [show, setShow] = useState(false);
    useEffect(() => {
        if (notificationMessage !== '') {
            setShow(true); // Change the key to force re-render
        }
    }, [notificationMessage]);

    useEffect(() => {
        (async () => {
            const locations = await AxiosGet('locations?id[neq]=' + params.id);
            setDropdown({ locations });
            const result = await AxiosGet('location/' + params.id);
            console.log(result)
            setValues({
                name: result.data.data.name,
                reference_id: result.data.data.reference_id,
                address: result.data.data.address,
                map_address: result.data.data.map_address,
                longitude: result.data.data.longitude,
                latitude: result.data.data.latitude,
                has_parent: (result.data.data.has_parent != 0) ? true : false,
                is_deleted: (result.data.data.is_deleted != 0) ? true : false,
                status: result.data.data.status,
                parent_location_id: (result.data.data.has_parent != 0) ? result.data.data.parent_location_id.id : '',
                notes: result.data.data.notes,
            });
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <ContentHeader
                h1='Edit Location'
                buttons={
                    [
                        { url: 'locations', label: 'All Locations' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'locations', label: 'Locations', active: false },
                        { url: 'location/edit', label: 'Edit', active: true },
                    ]
                }
            />
            <div className='border rounded-2 shadow-sm'>

                <form>
                    <div className="row p-3">
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='reference_id'
                                    type='text'
                                    displayName='Location Reference Id'
                                    className='form-control'
                                    value={values['reference_id']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='name'
                                    type='text'
                                    displayName='Location Name'
                                    className='form-control'
                                    value={values['name']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='address'
                                    type='text'
                                    displayName='Location Address'
                                    className='form-control'
                                    value={values['address']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='longitude'
                                    type='text'
                                    displayName='Longitude'
                                    className='form-control'
                                    value={values['longitude']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3">
                                <ComponentInput
                                    name='latitude'
                                    type='text'
                                    displayName='latitude'
                                    className='form-control'
                                    value={values['latitude']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="col-12">
                                <button onClick={
                                    (e) => {
                                        handleSubmitForUpdate(
                                            e,
                                            'location/edit',
                                            {
                                                id: params.id,
                                                reference_id: values.reference_id,
                                                name: values.name,
                                                address: values.address,
                                                longitude: values.longitude,
                                                latitude: values.latitude,
                                                notes: values.notes,
                                                map_address: values.map_address,
                                                has_parent: values.has_parent,
                                                is_deleted: values.is_deleted,
                                                status: values.status,
                                                parent_location_id: (values.has_parent === false) ? 0 : values.parent_location_id,
                                            },
                                            setError,
                                            setNotificationMessage
                                        )
                                    }
                                } className="btn btn-primary">Submit</button>
                                {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className='mt-3'>
                                    <Toast.Header>
                                        <img
                                            src="holder.js/20x20?text=%20"
                                            className="rounded me-2"
                                            alt=""
                                        />
                                        <strong className="me-auto">Location</strong>
                                        <small>Updated</small>
                                    </Toast.Header>
                                    <Toast.Body>{notificationMessage}</Toast.Body>
                                </Toast> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="mb-3">
                                <ComponentInput
                                    name='map_address'
                                    type='text'
                                    displayName='Google Map Link'
                                    className='form-control'
                                    value={values['map_address']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className="mb-3 ">
                                <div className="form-check ">
                                    <input className="form-check-input" type="checkbox" name='has_parent' id="hasParent"
                                        checked={values['has_parent']}
                                        onChange={(e) => onChange(e, values, setValues)} />
                                    <label className="form-check-label" htmlFor="hasParent">
                                        This is a child location
                                    </label>
                                    <span id="has_parent-error" className="error text-danger block">
                                        {(error) && error['has_parent']}
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label>Select Parent Location</label>
                                <select
                                    name='parent_location_id'
                                    className="form-control"
                                    required
                                    value={values['parent_location_id']}
                                    disabled={!values['has_parent']}
                                    onChange={(e) => onChange(e, values, setValues)}>
                                    <option>Select Parent</option>
                                    {
                                        dropdown.locations && dropdown.locations.data.data != '' && dropdown.locations.data.data != null &&
                                        dropdown.locations.data.data.map((item, i) =>
                                            <option value={item.id} key={i}>{item.name}</option>
                                        )
                                    }

                                </select>
                                <span id="categoryName-error" className="error text-danger">
                                    {(error) && error['parent_location_id']}
                                </span>

                            </div>
                            <div className="mb-3">
                                <ComponentTextarea
                                    name='notes'
                                    type='text'
                                    displayName='Notes'
                                    className='form-control'
                                    value={values['notes']}
                                    onChange={(e) => onChange(e, values, setValues)}
                                    error={error}
                                />
                            </div>
                            <div className='mb-3'>
                                {
                                    <ComponentSelect
                                        name='status'
                                        displayName='Status'
                                        className='form-select'
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' },
                                        ]}
                                        onChange={(e) => onChange(e, values, setValues)}
                                        selected={values['status']}
                                        label='label'
                                        value='value'
                                        error={error}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditLocation