import React, { useEffect, useState } from 'react';
import { AxiosGet, ContentHeader } from '../../Components/Components';
import { Link } from 'react-router-dom';
import { CustomTable, SelectColumnFilter } from '../../Components/CustomTable/CustomReactTable';
import { cookie } from '../../services/CookieService';
import { isEmpty } from 'lodash';
import { ComponentAction } from '../../Components/CustomTable/CustomReactTableComponents';


const Parts = () => {

    // React-table start
    const [data, setData] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [refetchColumns, setRefetchColumns] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Part Name',
                Cell: ({ cell }) => (
                    <>
                        <Link className='mx-2' to={`/part/detail/${cell.row.values.id}`}>
                            {cell.value}
                        </Link>
                    </>
                )
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
            {
                accessor: 'barcode',
                Header: 'Barcode',
            },
            {
                id: 'category_name',
                accessor: 'category.name',
                Header: 'Category',
                Filter: SelectColumnFilter,
            },
            {
                accessor: 'id',
                Header: 'Action',
                disableFilters: true,
                Cell: ({ cell }) => (
                    <>
                        <ComponentAction
                            data={cell.row.original}
                            target='part'
                        />
                    </>
                )
            },
        ],
        []
    )

    useEffect(() => {

        // cookie based column visibility start
        let cookies = cookie.getAll();
        if (cookies && !isEmpty(cookies) && cookies !== null) {
            let hiddenColumnNames = [];
            Object.keys(cookies).map((item, i) => {
                if (item.includes(window.location.hash + '-column|') && (cookies[item] === 'true')) {
                    hiddenColumnNames.push(item.split("|")[1]);
                    // console.log(item);
                    // console.log(cookies[item]);
                }
            });
            setHiddenColumns(hiddenColumnNames);
        }
        // cookie based column visibility end

        AxiosGet('parts').then(result => {
            setData(result.data.data);
        }).catch(err => {
            console.log(err.response, '----')
        })
    }, []);
    // React-table end

    return (
        <>
            <ContentHeader
                h1='Parts'
                buttons={
                    [
                        { url: 'part/add', label: 'Add Part' },
                    ]
                }
                breadcrumbs={
                    [
                        { url: '', label: 'Dashboard', active: false },
                        { url: 'parts', label: 'Parts', active: true },
                    ]
                }
            />
            <div className="row">
                <div className="col-12">
                    <CustomTable
                        columns={columns}
                        data={data}
                        height='auto'
                        toggleColumnVisiblity={true}
                        setRefetchColumns={setRefetchColumns}
                        hiddenColumns={hiddenColumns}
                        // filterData={[
                        //     {
                        //         id: "category_name",
                        //         value: "Category G"
                        //     }
                        // ]}
                    />
                </div>
            </div>
        </>
    );
};
export default Parts
